import React from 'react'
import { Box, Typography, useTheme } from '@mui/material'

export type ProgressIndicatorStatus =
  | 'complete'
  | 'current'
  | 'next'
  | 'overdue'

export type ProgressIndicatorProps = {
  text: string
  status: ProgressIndicatorStatus
  overText?: string
  width?: string
  noWrap?: boolean
}

const ProgressIndicator = (props: ProgressIndicatorProps) => {
  const { text, status, width, noWrap, overText } = props
  let progressColor
  let overTextColor

  const theme = useTheme()

  if (status === 'complete') {
    progressColor = theme.palette.progressComplete.main
    overTextColor = theme.palette.progressCompleteText.main
  } else if (status === 'current') {
    progressColor = theme.palette.progressCurrent.main
    overTextColor = theme.palette.progressCurrentText.main
  } else if (status === 'overdue') {
    progressColor = 'errorRed.main'
    overTextColor = 'errorRed.main'
  } else {
    progressColor = theme.palette.progressFuture.main
    overTextColor = theme.palette.progressFutureText.main
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{ width, transition: 'width 300ms ease' }}
    >
      {overText && (
        <Typography variant="p3" color={overTextColor}>
          {overText}
        </Typography>
      )}
      <Box
        height="4px"
        bgcolor={progressColor}
        sx={{ transition: 'background-color 1s ease' }}
      />
      <Typography
        // @ts-ignore
        variant={theme.progressIndicatorOptions?.fontVariant || 'p3'}
        sx={{
          wordWrap: noWrap ? 'unset' : 'break-word',
        }}
        color={overTextColor}
      >
        {text}
      </Typography>
    </Box>
  )
}

ProgressIndicator.defaultProps = {
  width: '93px',
  noWrap: false,
  overText: '',
}

export default ProgressIndicator
