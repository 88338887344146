import React from 'react'
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material'
import {
  MultifamilyIconIcon,
  SfrIconIcon,
  HouseOutlinedIcon,
  MoneyOutlinedIcon,
  MapPinFilledIcon,
} from '@flock/shared-ui'
import millify from 'millify'
import { DoorFrontOutlined } from '@mui/icons-material'
import HomeDetail from './HomeDetail'

type PortfolioHomeDetailsProps = {
  leadName?: string
  portfolioName?: string
  portfolioDoorCount?: number
  portfolioAssetCount?: number
  portfolioCityState?: string
  portfolioSFRDoors?: number
  portfolioMultifamilyDoors?: number
  capex?: number
  offerPrice?: number
}

const PortfolioHomeDetails = (props: PortfolioHomeDetailsProps) => {
  const {
    leadName,
    portfolioName,
    portfolioDoorCount,
    portfolioAssetCount,
    portfolioCityState,
    portfolioSFRDoors,
    portfolioMultifamilyDoors,
    capex,
    offerPrice,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const formattedOfferPrice = isMobile
    ? millify(offerPrice!, {
        precision: 1,
        lowercase: true,
        units: ['', 'k', 'm', 'b', 't'],
      })
    : millify(offerPrice!, {
        precision: 1,
        lowercase: true,
        units: ['', 'k', ' million', ' billion', ' trillion'],
      })

  const PortfolioHomeDetailComponents = !isMobile ? (
    <Box display="flex" flexDirection="column" gap="6px" width="100%">
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        gap="8px"
        width="100%"
      >
        {portfolioSFRDoors ? (
          <Box width="50%">
            <HomeDetail
              icon={SfrIconIcon}
              text={`${portfolioSFRDoors} SFR doors`}
            />
          </Box>
        ) : null}
        {offerPrice && portfolioDoorCount ? (
          <Box width="50%">
            <HomeDetail
              icon={MoneyOutlinedIcon}
              text={`$${Math.round(
                offerPrice / portfolioDoorCount
              ).toLocaleString()} avg per door`}
            />
          </Box>
        ) : null}
      </Box>

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        gap="8px"
        width="100%"
      >
        {capex && portfolioDoorCount ? (
          <Box width="50%">
            <HomeDetail
              icon={MoneyOutlinedIcon}
              text={`$${Math.round(
                capex / portfolioDoorCount
              ).toLocaleString()} avg capex/door`}
            />
          </Box>
        ) : null}
        {portfolioMultifamilyDoors ? (
          <Box width="50%">
            <HomeDetail
              icon={MultifamilyIconIcon}
              text={`${portfolioMultifamilyDoors} multifamily doors`}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" gap="6px" width="100%">
      <Box display="flex" flexDirection="row" gap="6px" width="100%">
        {portfolioDoorCount ? (
          <Box width="50%">
            <HomeDetail
              icon={HouseOutlinedIcon}
              text={`${portfolioDoorCount} doors`}
            />
          </Box>
        ) : null}
        {portfolioAssetCount ? (
          <Box width="50%">
            <HomeDetail
              icon={DoorFrontOutlined}
              text={`${portfolioAssetCount} assets`}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box display="flex" flexDirection="row">
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            boxShadow: 8,
            borderRadius: '8px',
            backgroundColor: 'green0.main',
            padding: isMobile ? '16px' : '16px 32px',
          }}
        >
          <Typography
            sx={{ paddingBottom: isMobile ? '8px' : '16px' }}
            variant="cta2"
          >
            {portfolioName || `${leadName} Portfolio`}
          </Typography>
          <>{PortfolioHomeDetailComponents}</>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap="16px">
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          sx={{
            boxShadow: 8,
            borderRadius: '8px',
            backgroundColor: 'green0.main',
            padding: '16px',
          }}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap="8px"
          >
            <MapPinFilledIcon
              width={isMobile ? '24px' : '56px'}
              height={isMobile ? '24px' : '56px'}
            />
            <Box display="flex" flexDirection="column" gap="4px">
              <Typography>
                <b>{portfolioCityState}</b>
              </Typography>
              <Typography variant="p2">portfolio location</Typography>
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          sx={{
            boxShadow: 8,
            borderRadius: '8px',
            backgroundColor: 'green0.main',
            padding: '16px',
          }}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gap="8px"
          >
            <MoneyOutlinedIcon
              width={isMobile ? '24px' : '56px'}
              height={isMobile ? '24px' : '56px'}
            />
            <Box display="flex" flexDirection="column" gap="4px">
              <Typography>
                <b>${formattedOfferPrice}</b>
              </Typography>
              <Typography variant="p2">your Flock valuation</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PortfolioHomeDetails

PortfolioHomeDetails.defaultProps = {
  leadName: '',
  portfolioName: '',
  portfolioDoorCount: 0,
  portfolioAssetCount: 0,
  portfolioCityState: '',
  portfolioSFRDoors: 0,
  portfolioMultifamilyDoors: 0,
  capex: 0,
  offerPrice: 0,
}
