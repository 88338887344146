import {
  Box,
  Typography,
  Paper,
  Slider,
  styled,
  TextField,
} from '@mui/material'
import {
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ComposedChart,
} from 'recharts'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { calculateFlexibleCashFlowModel } from '@flock/utils/src/money/flexibleCashFlowCalculator'
import millify from 'millify'
import { FlexibleCashFlowPresentationalProps } from './flexibleCashflowCalculatorTypes'
import TapAPointImage from '../../../images/tap-a-point.webp'

const StyledSlider = styled(Slider)({
  '& .MuiSlider-track': {
    height: '2px',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '3px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
})

const StyledComposedChart = styled(ComposedChart)({
  '& .recharts-cartesian-grid > .recharts-cartesian-grid-horizontal > line:nth-last-child(2)':
    {
      display: 'none!important',
    },
})

const getCashFlowRange = (netYield: number) => {
  if (netYield < 0.06) {
    return Math.round(netYield * 10000) / 100
  }
  return 6
}

const FlexibleCashflowCalculatorMobile = (
  props: FlexibleCashFlowPresentationalProps
) => {
  const {
    startingInvestment,
    hideNetYield,
    uwCashOnCashYield: netYield,
    showCashFlowDeductions: overrideShowDeductions,
    holdingPeriodOverride,
  } = props

  const tickFormatterY = useCallback((val: any) => {
    const precision = val / 100 < 100000 || val / 100 > 1000000 ? 1 : 0 // if val is between 100k and 1m, show no decimal places since we will overflow on the chart
    return `$${millify(val, {
      precision,
      lowercase: true,
      units: ['', 'K', 'M', 'B', 'T'],
    })}`
  }, [])

  const cashFlowRange = getCashFlowRange(netYield)
  const currentDate = new Date()
  currentDate.setMonth(currentDate.getMonth() + 1)
  const currentYear = currentDate.getFullYear()

  const [activeLabel, setActiveLabel] = useState(`${currentYear + 4}`)
  const [cashFlowSlider, setCashFlowSlider] = useState(
    hideNetYield ? 0 : cashFlowRange - 2
  )
  const flexibleCashFlowOutputs = calculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield,
    cashFlowPercentage: 0.01 * cashFlowSlider,
    holdingPeriodOverride,
    hideNetYield,
  })

  const noCashFlowOutputs = calculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield,
    cashFlowPercentage: 0,
    holdingPeriodOverride,
    hideNetYield,
  })
  const graphDomain = [
    noCashFlowOutputs.initialAccountValueLow[0],
    noCashFlowOutputs.initialAccountValueHigh[4],
  ]

  const data = [
    {
      name: `${currentYear}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[0],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[0],
        flexibleCashFlowOutputs.initialAccountValueLow[0],
      ],
    },
    {
      name: `${currentYear + 1}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[1],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[1],
        flexibleCashFlowOutputs.initialAccountValueLow[1],
      ],
    },
    {
      name: `${currentYear + 2}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[2],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[2],
        flexibleCashFlowOutputs.initialAccountValueLow[2],
      ],
    },
    {
      name: `${currentYear + 3}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[3],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[3],
        flexibleCashFlowOutputs.initialAccountValueLow[3],
      ],
    },
    {
      name: `${currentYear + 4}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[4],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[4],
        flexibleCashFlowOutputs.initialAccountValueLow[4],
      ],
    },
  ]

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      setActiveLabel(label)
    } else if (!active) {
      setActiveLabel(`${currentYear + 4}`)
    }

    return null
  }

  const PotentialAnnualEarnings = (
    <Box display="flex" flexDirection="column" gap="4px">
      <Typography variant="p2" color="gray8.main">
        Your starting account value
      </Typography>

      <Typography
        variant="h4"
        color="moneyGreen.main"
        sx={{
          fontWeight: 700,
        }}
      >
        ${startingInvestment.toLocaleString()}
      </Typography>

      <Typography variant="c1" color="green3.main">
        Target annualized return of 8-12% comprised of cash flow & appreciation
      </Typography>
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Paper
        sx={{
          padding: '16px',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
        }}
      >
        <Box display="flex" gap="8px" flexDirection="column">
          {PotentialAnnualEarnings}
        </Box>
      </Paper>

      {!hideNetYield && (
        <Box>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              gap="16px"
            >
              <Typography variant="p2">I want to take in cash flow</Typography>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box width="160px" height="40px!important">
                  <TextField
                    name="email"
                    type="money"
                    value={`$${Math.round(
                      cashFlowSlider *
                        flexibleCashFlowOutputs.initialAccountValueAvg[0] *
                        0.01
                    ).toLocaleString()}`}
                    variant="outlined"
                    size="small"
                    fullWidth
                    inputProps={{
                      inputMode: 'numeric',
                      min: 0,
                      style: { textAlign: 'center', padding: '0px!important' },
                    }}
                    sx={{
                      '& > input': {
                        padding: 0,
                      },
                      width: '215px',
                      '& > div > input': {
                        fontSize: '1rem!important',
                        padding: '0!important',
                        height: '40px!important',
                      },
                    }}
                    InputLabelProps={{
                      style: { padding: '0px!important' },
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (
                        e.target.value[0] === '$' &&
                        Number(e.target.value.replace(/,/g, '').substring(1)) <=
                          cashFlowRange *
                            flexibleCashFlowOutputs.initialAccountValueAvg[0] *
                            0.01
                      ) {
                        setCashFlowSlider(
                          (Math.abs(
                            Number(
                              e.target.value.replace(/,/g, '').substring(1)
                            )
                          ) *
                            100) /
                            flexibleCashFlowOutputs.initialAccountValueAvg[0]
                        )
                      } else if (
                        Number(e.target.value) <=
                        cashFlowRange *
                          flexibleCashFlowOutputs.initialAccountValueAvg[0] *
                          0.01
                      ) {
                        setCashFlowSlider(
                          Math.abs(Number(e.target.value) * 100) /
                            flexibleCashFlowOutputs.initialAccountValueAvg[0]
                        )
                      } else {
                        // TODO - Add in pop-up here
                        setCashFlowSlider(cashFlowRange)
                      }
                    }}
                  />
                </Box>
                <Typography variant="p2">/ year</Typography>
              </Box>
            </Box>
            <StyledSlider
              min={0}
              value={cashFlowSlider}
              defaultValue={cashFlowRange - 2}
              onChange={(_, value) =>
                setCashFlowSlider(Array.isArray(value) ? value[0] : value)
              }
              max={cashFlowRange}
              step={0.01}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" gap="32px">
            <Typography variant="c1">$0</Typography>
            <Typography variant="c1" color="gray5.main">
              $
              {Math.round(
                cashFlowRange *
                  flexibleCashFlowOutputs.initialAccountValueAvg[0] *
                  0.01
              ).toLocaleString()}
            </Typography>
          </Box>
        </Box>
      )}
      <Paper
        sx={{
          padding: '16px',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Box>
            <Box display="flex" flexDirection="column" gap="8px">
              <Box width="100%">
                <Box display="flex" gap="4px" flexDirection="column">
                  <Box
                    display="flex"
                    gap="8px"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Typography variant="p2" color="gray8.main">
                      Projected return after one year
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="8px"
                    alignItems="flex-end"
                  >
                    <Typography
                      variant="h4"
                      color="moneyGreen.main"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      $
                      {Math.round(
                        flexibleCashFlowOutputs.totalReturnAvg[0]
                      ).toLocaleString()}
                    </Typography>
                    <Box>
                      <Typography variant="c1" color="gray5.main">
                        10% total return
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {!hideNetYield && (
                <Box width="100%">
                  <Box display="flex" gap="4px" flexDirection="column">
                    <Box
                      display="flex"
                      gap="8px"
                      flexDirection="row"
                      alignItems="center"
                    >
                      <Typography
                        variant="p2"
                        color="gray8.main"
                        sx={{ whiteSpace: 'nowrap' }}
                      >
                        Cash taken after one year
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      gap="8px"
                      alignItems="flex-end"
                    >
                      <Typography
                        variant="h4"
                        color="green4.main"
                        sx={{
                          fontWeight: 700,
                        }}
                      >
                        $
                        {Math.round(
                          flexibleCashFlowOutputs.cashWithdrawnAvg[0]
                        ).toLocaleString()}
                      </Typography>
                      <Typography variant="c1" color="gray5.main">
                        {cashFlowSlider.toFixed(2)}% cash flow
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          padding: '16px',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
        }}
      >
        <Box display="flex" flexDirection="column" gap="8px">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="p1">Projected 5 Year Returns</Typography>
          </Box>
          <Paper
            sx={{
              padding: '8px',
              backgroundColor: 'green0.main',
              borderRadius: '8px',
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                component="img"
                src={TapAPointImage}
                height="36px"
                width="36px"
              />
              <Typography variant="c1" color="moneyGreen.main" width="160px">
                Tap a point on the graph to show earnings for that year.
              </Typography>
            </Box>
          </Paper>
          <Box>
            <ResponsiveContainer width="100%" height={200}>
              <StyledComposedChart
                width={530}
                height={200}
                data={data}
                margin={{ top: 5, right: 25, left: 5, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  style={{
                    fontFamily: 'Outfit',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                  interval={1}
                />
                <YAxis
                  scale="linear"
                  width={40}
                  domain={graphDomain}
                  tickFormatter={tickFormatterY}
                  tickCount={3}
                  style={{
                    fontFamily: 'Outfit',
                    fontWeight: 400,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                />
                <Tooltip content={<CustomTooltip active payload label />} />
                <Legend />
                <Area
                  legendType="none"
                  type="monotone"
                  dataKey="range"
                  fill="#C9DADB"
                  stroke="#C9DADB"
                />
                <Line
                  legendType="none"
                  type="monotone"
                  dataKey="Total Return"
                  stroke="#03341D"
                  strokeWidth={2}
                />
              </StyledComposedChart>
            </ResponsiveContainer>
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="8px"
            alignItems="center"
          >
            <Box width="100px" />
            <Typography variant="p4" width="85px" sx={{ textAlign: 'right' }}>
              {activeLabel}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="8px"
            alignItems="center"
          >
            <Typography
              variant="p4"
              width="100px"
              sx={{ whiteSpace: 'nowrap' }}
            >
              Initial Value
            </Typography>
            <Typography variant="p4" width="85px" sx={{ textAlign: 'right' }}>
              $
              {Math.round(
                flexibleCashFlowOutputs.initialAccountValueAvg[
                  Number(activeLabel) - currentYear
                ]
              ).toLocaleString()}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="8px"
            alignItems="center"
          >
            <Typography
              variant="p4"
              color="gray8.main"
              width="100px"
              sx={{ whiteSpace: 'nowrap' }}
            >
              Total Return
            </Typography>
            <Typography
              variant="p4"
              width="85px"
              color="gray8.main"
              sx={{ textAlign: 'right' }}
            >
              $
              {Math.round(
                flexibleCashFlowOutputs.totalReturnAvg[
                  Number(activeLabel) - currentYear
                ]
              ).toLocaleString()}
            </Typography>
          </Box>
          {!hideNetYield && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              pb="4px"
              alignItems="center"
            >
              <Typography
                variant="p4"
                width="100px"
                sx={{ whiteSpace: 'nowrap' }}
                color="green4.main"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Cash Taken
              </Typography>
              <Typography
                variant="p4"
                width="85px"
                color="green4.main"
                sx={{ textAlign: 'right' }}
              >
                $
                {Math.round(
                  flexibleCashFlowOutputs.cashWithdrawnAvg[
                    Number(activeLabel) - currentYear
                  ]
                ).toLocaleString()}
              </Typography>
            </Box>
          )}
          {overrideShowDeductions ? (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              pb="8px"
              alignItems="center"
            >
              <Typography
                variant="p4"
                width="100px"
                sx={{ whiteSpace: 'nowrap' }}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Deductions<sup>1</sup>
              </Typography>
              <Typography variant="p4" width="85px" sx={{ textAlign: 'right' }}>
                $
                {Math.round(
                  flexibleCashFlowOutputs.cashDeductionAvg[
                    Number(activeLabel) - currentYear
                  ]
                ).toLocaleString()}
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Paper>
    </Box>
  )
}

export default FlexibleCashflowCalculatorMobile
