import { DEFAULT_CLOSING_AND_LEGAL_FEE } from '@flock/utils'
import { useMediaQuery, useTheme } from '@mui/material'
import {
  FlexibleCashFlowProps,
  FlexibleCashFlowPresentationalProps,
} from './flexibleCashflowCalculatorTypes'

const useAppreciationPotential: (
  props: FlexibleCashFlowProps
) => FlexibleCashFlowPresentationalProps = (props: FlexibleCashFlowProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const {
    initialValuation,
    mortgageAmount,
    onboardingFee,
    equityOverride,
    cashTakeout,
    rentReduction,
    daysInRemodelDeduction,
    shouldOverrideClosingCosts,
    closingCostsOverride,
    netYieldOverride,
  } = props

  let closingCosts = DEFAULT_CLOSING_AND_LEGAL_FEE
  if (shouldOverrideClosingCosts) {
    closingCosts = closingCostsOverride
  }

  // This is valuation, less all fees and reductions
  const feeModifier = 1 - onboardingFee
  const startingInvestment =
    equityOverride ||
    Math.round(
      Math.round(initialValuation * feeModifier) -
        Math.round(initialValuation * closingCosts) -
        Math.round(mortgageAmount) -
        Math.round(rentReduction) -
        Math.round(daysInRemodelDeduction) -
        Math.round(cashTakeout)
    )

  let { uwCashOnCashYield } = props
  if (netYieldOverride) {
    uwCashOnCashYield = netYieldOverride
  }

  return {
    ...props,

    uwCashOnCashYield,

    isMobile,
    isTablet,

    startingInvestment,
  }
}

export default useAppreciationPotential
