import {
  TrackedLink,
  HouseOutlinedIcon,
  MoneySymbolIcon,
  BankOutlinedIcon,
} from '@flock/shared-ui'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import {
  Core_LeadStage,
  Core_TransactionType,
  Core_ValuationType,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import OfferPageDrawer from './OfferPageDrawer'
import OfferBasisComponent from './OfferBasisComponent'
import { PROPERTY_STANDARDS_URL } from '../../constants'
import { LeadStatus } from './offerPageTypes'
import ProgressBarDisplay from './ProgressBarDisplay'
import { hideExpirationLeadStages } from '../utils'
import OfferPagePaper from './OfferPagePaper'

type HasOfferDetailsDisplayProps = {
  street: string
  cityStateZipcode: string
  finalOffer: number
  showRangedValuation: boolean
  offerLow: number
  offerHigh: number
  saamValuationType: Core_ValuationType | undefined
  transactionType: string
}

const getOfferDescription = (
  finalOffer: number,
  saamValuationType: Core_ValuationType | undefined,
  showRangedValuation: boolean,
  transactionType: string
) => {
  let descriptionPrefix = 'Your'
  if (transactionType === Core_TransactionType.TransactionTypePortfolio_721) {
    descriptionPrefix = 'The'
  }

  let offerDescription = 'valuation' // should never really happen for now
  if (!finalOffer || showRangedValuation) {
    offerDescription = 'valuation range'
  } else if (
    saamValuationType === Core_ValuationType.ValuationTypePreliminary ||
    saamValuationType === Core_ValuationType.ValuationTypeInitial
  ) {
    offerDescription = 'initial valuation'
  } else if (saamValuationType === Core_ValuationType.ValuationTypeFinal) {
    offerDescription = 'final valuation'
  }
  return `${descriptionPrefix} ${offerDescription}`
}

// behavior: if it's a prelim valuation, it will always be an offer range. The only time a single number is shown is if it's a final valuation

const HasOfferDetailsDisplay = (props: HasOfferDetailsDisplayProps) => {
  const {
    street,
    cityStateZipcode,
    finalOffer,
    showRangedValuation,
    offerLow,
    offerHigh,
    saamValuationType,
    transactionType,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const offerDescription = getOfferDescription(
    finalOffer,
    saamValuationType,
    showRangedValuation,
    transactionType
  )
  return (
    <Box display="flex" flexDirection="column" gap="8px" textAlign="center">
      {finalOffer !== 0 && !showRangedValuation && (
        <>
          <Box>
            <Typography variant="c1" fontWeight="400" fontSize="14px">
              {offerDescription} for
            </Typography>

            {transactionType ===
            Core_TransactionType.TransactionTypePortfolio_721 ? (
              <Typography variant="c1" fontSize="14px">
                {' '}
                your portfolio{' '}
              </Typography>
            ) : (
              <Typography variant="c1" fontSize="14px">
                {' '}
                {street}
                {cityStateZipcode.trim() ? ',' : ''}{' '}
              </Typography>
            )}
            <span style={{ whiteSpace: 'nowrap' }}>
              {transactionType !==
                Core_TransactionType.TransactionTypePortfolio_721 && (
                <Typography variant="c1" fontSize="14px">
                  {cityStateZipcode}{' '}
                </Typography>
              )}
              <Typography variant="c1" fontWeight="400" fontSize="14px">
                is
              </Typography>
            </span>
          </Box>
          <Box>
            <Typography variant="h1">
              ${finalOffer?.toLocaleString()}
            </Typography>
          </Box>
        </>
      )}
      {/* New Mailer goes out with offer range - we don't want to show a different offer range if they go through flow.
        uncomment at a later time. */}
      {offerLow !== 0 && offerHigh !== 0 && showRangedValuation && (
        <>
          <Box>
            <Typography variant="c1" fontWeight="400" fontSize="14px">
              {offerDescription} for
            </Typography>
            {transactionType ===
            Core_TransactionType.TransactionTypePortfolio_721 ? (
              <Typography variant="c1" fontSize="14px">
                {' '}
                your portfolio{' '}
              </Typography>
            ) : (
              <Typography variant="c1" fontSize="14px">
                {' '}
                {street}
                {cityStateZipcode.trim() ? ',' : ''}{' '}
              </Typography>
            )}
            <span style={{ whiteSpace: 'nowrap' }}>
              {transactionType !==
                Core_TransactionType.TransactionTypePortfolio_721 && (
                <Typography variant="c1" fontSize="14px">
                  {cityStateZipcode}{' '}
                </Typography>
              )}
              <Typography variant="c1" fontWeight="400" fontSize="14px">
                is
              </Typography>
            </span>
          </Box>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            flexWrap={offerLow >= 10000000 ? undefined : 'wrap'}
          >
            <Typography variant="h2" fontSize="2.5rem">
              ${offerLow?.toLocaleString()}
            </Typography>
            <Typography variant="h2" fontSize="2.5rem">
              -
            </Typography>
            <Typography variant="h2" fontSize="2.5rem">
              ${offerHigh?.toLocaleString()}
            </Typography>
          </Box>
          <Box
            width="100%"
            height="8px"
            sx={{
              backgroundImage:
                'linear-gradient(90deg, #004021 0.11%, rgba(0, 64, 33, 0.2) 50.23%, #004021 100%)',
            }}
          />
        </>
      )}
    </Box>
  )
}

type NoOfferDetailsDisplayProps = {
  street: string
}

const NoOfferDetailsDisplay = (props: NoOfferDetailsDisplayProps) => {
  const { street } = props
  return (
    <Box display="flex" flexDirection="column" gap="8px" textAlign="center">
      <Typography variant="c1" fontWeight="500px" fontSize="1rem">
        We&#39;re generating your initial valuation for
      </Typography>

      <Typography variant="h2">{street}</Typography>
      <Typography variant="c1" fontWeight="500px">
        We&#39;ll email you your initial valuation within 24 hours, guaranteed.
      </Typography>
    </Box>
  )
}

type ExpiredOfferDetailsDisplayProps = {
  street: string
  cityStateZipcode: string
  expiryDateDisplay: string
  finalOffer: number
  showRangedValuation: boolean
  offerLow: number
  offerHigh: number
  saamValuationType: Core_ValuationType | undefined
  transactionType: string
}

const ExpiredOfferDetailsDisplay = (props: ExpiredOfferDetailsDisplayProps) => {
  const {
    street,
    cityStateZipcode,
    expiryDateDisplay,
    finalOffer,
    showRangedValuation,
    offerLow,
    offerHigh,
    saamValuationType,
    transactionType,
  } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const offerDescription = getOfferDescription(
    finalOffer,
    saamValuationType,
    showRangedValuation,
    transactionType
  )

  return (
    <Box display="flex" flexDirection="column" gap="8px" textAlign="center">
      <Box>
        <Typography variant="c1" fontWeight="400px" fontSize="14px">
          {offerDescription} for
        </Typography>
        {transactionType ===
        Core_TransactionType.TransactionTypePortfolio_721 ? (
          <Typography variant="c1" fontSize="14px">
            {' '}
            your portfolio{' '}
          </Typography>
        ) : (
          <Typography variant="c1" fontSize="14px">
            {' '}
            {street}
            {cityStateZipcode.trim() ? ',' : ''}{' '}
          </Typography>
        )}
        <span style={{ whiteSpace: 'nowrap' }}>
          {transactionType !==
            Core_TransactionType.TransactionTypePortfolio_721 && (
            <Typography variant="c1" fontSize="14px">
              {cityStateZipcode}{' '}
            </Typography>
          )}
          <Typography variant="c1" fontWeight="400" fontSize="14px">
            was
          </Typography>
        </span>
      </Box>
      {finalOffer && !showRangedValuation ? (
        <Typography variant="h2"> ${finalOffer?.toLocaleString()} </Typography>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            flexWrap={offerLow >= 10000000 ? undefined : 'wrap'}
          >
            <Typography variant="h2" fontSize="2.5rem">
              ${offerLow?.toLocaleString()}
            </Typography>
            <Typography variant="h2" fontSize="2.5rem">
              -
            </Typography>
            <Typography variant="h2" fontSize="2.5rem">
              ${offerHigh?.toLocaleString()}
            </Typography>
          </Box>
          <Box
            width="100%"
            height="8px"
            sx={{
              backgroundImage:
                'linear-gradient(90deg, #891A1A 0.11%, rgba(137, 26, 26, 0.2) 50.23%, #891A1A 100%)',
            }}
          />
        </>
      )}

      <Typography variant="c1" color="errorRed.main" fontWeight="500px">
        This valuation expired on {expiryDateDisplay}.
      </Typography>
    </Box>
  )
}

type OfferDetailsProps = {
  name: string
  street: string
  zipcode: string
  cityStateZipcode: string
  hasCityStateZipcodeOverride: boolean
  expiryDate: Date
  latitude: number
  longitude: number
  hideValuation: boolean
  finalOffer: number
  prelimOffer: number
  showRangedValuation: boolean
  offerLow: number
  offerHigh: number
  propertyCondition: string
  leadStage: Core_LeadStage
  isOverriddenAddress: boolean
  saamValuationType: Core_ValuationType | undefined
  leadStatus: LeadStatus
  inspectionDate?: Date
  leadUuid: string
  transactionType: string
  scheduleInspectionRequested: boolean
  updateScheduleInspectionRequested: (requested: boolean) => void
  contributionAgreementRequested: boolean
  updateContributionAgreementRequested: (requested: boolean) => void
  updateRefreshOfferRequested: (requested: Date) => void
  refreshOfferRequested: Date
  uwCashOnCashYield: number
  equityAmount: number
}

const OfferDetails = (props: OfferDetailsProps) => {
  const {
    name,
    street,
    zipcode,
    cityStateZipcode,
    hasCityStateZipcodeOverride,
    expiryDate,
    latitude,
    longitude,
    finalOffer,
    showRangedValuation,
    offerLow,
    offerHigh,
    hideValuation,
    propertyCondition,
    leadStage,
    isOverriddenAddress,
    saamValuationType,
    leadStatus,
    inspectionDate,
    transactionType,
    leadUuid,
    scheduleInspectionRequested,
    updateScheduleInspectionRequested,
    contributionAgreementRequested,
    updateContributionAgreementRequested,
    updateRefreshOfferRequested,
    refreshOfferRequested,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  let zipcodeDisplay = zipcode
  if (hasCityStateZipcodeOverride) {
    zipcodeDisplay = 'your area'
  }

  let isExpired = new Date().getTime() > expiryDate.getTime()
  const isValidExpiration = expiryDate.getFullYear() > 2000
  const hideExpiry = hideExpirationLeadStages.includes(leadStage)
  if (!isValidExpiration || hideExpiry || !finalOffer) {
    isExpired = false
  }
  const expiryDateDisplay = expiryDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  const [isRentConditionDrawerOpen, setIsRentConditionDrawerOpen] =
    useState(false)
  const [isRentalCompsDrawerOpen, setIsRentalCompsDrawerOpen] = useState(false)
  const [isPortfolioDrawerOpen, setIsPortfolioDrawerOpen] = useState(false)
  return (
    <Box display="flex" flexDirection="column" gap="32px" width="100%">
      <OfferPageDrawer
        title="Self-reported condition"
        icon={HouseOutlinedIcon}
        description={
          <>
            We use the information about rent and condition you provided to
            estimate the amount of work it would take to bring the property up
            to{' '}
            <TrackedLink
              onClick={() => navigate(PROPERTY_STANDARDS_URL)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Flock Standards.
            </TrackedLink>
          </>
        }
        graphic={
          <OfferBasisComponent
            offerBaseType="home_condition"
            propertyCondition={propertyCondition}
            address={street}
            cityStateZip={cityStateZipcode}
            isOverriddenAddress={isOverriddenAddress}
          />
        }
        isOpen={isRentConditionDrawerOpen}
        onClose={() => setIsRentConditionDrawerOpen(false)}
      />
      <OfferPageDrawer
        title={`Rental comps in ${zipcodeDisplay}`}
        icon={MoneySymbolIcon}
        description="We evaluate the state of the rental market in your neighborhood to see where your home stands."
        graphic={
          <OfferBasisComponent
            offerBaseType="rental_comps"
            address={street}
            cityStateZip={cityStateZipcode}
            latitude={latitude}
            longitude={longitude}
          />
        }
        isOpen={isRentalCompsDrawerOpen}
        onClose={() => setIsRentalCompsDrawerOpen(false)}
      />
      <OfferPageDrawer
        title="Flock Fund requirements"
        icon={BankOutlinedIcon}
        description="We base our valuation on projected income for the property. We protect all our investors by ensuring your property qualifies for entry into the Flock portfolio."
        graphic={<OfferBasisComponent offerBaseType="portfolio_requirements" />}
        isOpen={isPortfolioDrawerOpen}
        onClose={() => setIsPortfolioDrawerOpen(false)}
      />
      {name ? (
        <Typography variant="h3">Hi, {name}!</Typography>
      ) : (
        <Typography variant="h3">Hi, welcome to Flock!</Typography>
      )}

      <OfferPagePaper>
        <Box display="flex" flexDirection="column" gap="16px">
          {isExpired && (
            <ExpiredOfferDetailsDisplay
              street={street}
              cityStateZipcode={cityStateZipcode}
              expiryDateDisplay={expiryDateDisplay}
              showRangedValuation={showRangedValuation}
              finalOffer={finalOffer}
              offerLow={offerLow}
              offerHigh={offerHigh}
              saamValuationType={saamValuationType}
              transactionType={transactionType}
            />
          )}
          {!hideValuation && !isExpired && (
            <HasOfferDetailsDisplay {...props} />
          )}
          {hideValuation && !isExpired && (
            <NoOfferDetailsDisplay street={street || ''} />
          )}

          <Box
            display="flex"
            alignSelf="center"
            sx={{
              width: isMobile ? '272px' : '496px',
              borderTop: '1px solid grey',
            }}
          />

          <Box display="flex" flexDirection="column" gap="32px">
            <ProgressBarDisplay
              isExpired={isExpired}
              leadStatus={leadStatus}
              inspectionDate={inspectionDate!}
              zipcode={zipcode}
              transactionType={transactionType}
              leadUuid={leadUuid}
              scheduleInspectionRequested={scheduleInspectionRequested}
              updateScheduleInspectionRequested={
                updateScheduleInspectionRequested
              }
              contributionAgreementRequested={contributionAgreementRequested}
              updateContributionAgreementRequested={
                updateContributionAgreementRequested
              }
              updateRefreshOfferRequested={updateRefreshOfferRequested}
              refreshOfferRequested={refreshOfferRequested}
            />
          </Box>
        </Box>
      </OfferPagePaper>
    </Box>
  )
}

export default OfferDetails

OfferDetails.defaultProps = {
  inspectionDate: undefined,
}
