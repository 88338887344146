import { useMediaQuery, useTheme } from '@mui/material'
import { OfferPageSidebarProps } from './offerPageSidebarTypes'

const useOfferPageSidebar = (props: OfferPageSidebarProps) => {
  const {
    sidebarRef,
    sidebarHeight,
    leadStatus,
    sidebarItems,
    onChangeSidebar,
    selectedSection,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  return {
    sidebarRef,
    sidebarHeight,
    leadStatus,
    sidebarItems,
    onChangeSidebar,
    selectedSection,

    isMobile,
    isTablet,
  }
}

export default useOfferPageSidebar
