import {
  CaseTypes,
  CompoundingGrowth,
  defaultCaseConfigurations,
  DEFAULT_CLOSING_AND_LEGAL_FEE,
  DEFAULT_DISTRIBUTION_FACTOR,
  DEFAULT_FUND_EQUITY_PERCENT_TARGET,
  projectMultiCaseFinancialModel,
} from '@flock/utils'
import { useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useState } from 'react'
import {
  AppreciationPotentialProps,
  AppreciationPotentialPresentationalProps,
} from './appreciationPotentialTypes'

const useAppreciationPotential: (
  props: AppreciationPotentialProps
) => AppreciationPotentialPresentationalProps = (
  props: AppreciationPotentialProps
) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const {
    leadUuid,
    initialValuation,
    isPreliminary,
    mortgageAmount,
    hideValuation,
    onboardingFee,
    lowerValuationNumber,
    upperValuationNumber,
    equityOverride,
    cashTakeout,
    rentReduction,
    daysInRemodelDeduction,
    shouldOverrideClosingCosts,
    closingCostsOverride,
    capexCosts,
  } = props

  const [annualAppreciationPercent, setAnnualAppreciationPercent] =
    useState<number>(3)
  const [projectionCase, setProjectionCase] = useState<string>('avg')
  const [isHighSelected, setIsHighSelected] = useState<boolean>(false)
  const [isAvgSelected, setIsAvgSelected] = useState<boolean>(true)
  const [isLowSelected, setIsLowSelected] = useState<boolean>(false)

  const handleHighOnClick = () => {
    setProjectionCase('high')
    setIsHighSelected(true)
    setIsAvgSelected(false)
    setIsLowSelected(false)
    setAnnualAppreciationPercent(5)
  }

  const handleAvgOnClick = () => {
    setProjectionCase('avg')
    setIsHighSelected(false)
    setIsAvgSelected(true)
    setIsLowSelected(false)
    setAnnualAppreciationPercent(3)
  }

  const handleLowOnClick = () => {
    setProjectionCase('low')
    setIsHighSelected(false)
    setIsAvgSelected(false)
    setIsLowSelected(true)
    setAnnualAppreciationPercent(0)
  }

  const incomeTooltip = `Model assumes the Fund meets its annual distribution target.`
  const appreciationTooltip = `Flock equity changes in value due to portfolio net income and asset valuations. Model assumes equity in Flock appreciates commensurately with the broader real estate market outlook. This scenario analysis assumes a ${
    100 * (1 - DEFAULT_FUND_EQUITY_PERCENT_TARGET)
  }% leverage ratio.`
  const reinvestDistributionsTooltip =
    'Reinvesting distributions actually purchases more equity in Flock - this can lead to a compounding effect on returns that builds over time.'

  const [timeHorizonSlider, setTimeHorizonSlider] = useState<number>(5)
  const [isReinvestmentSelected, setIsReinvestmentSelected] = useState(false)
  const toggleIsReinvestmentSelected = useCallback(() => {
    setIsReinvestmentSelected(!isReinvestmentSelected)
  }, [isReinvestmentSelected])

  let closingCosts = DEFAULT_CLOSING_AND_LEGAL_FEE
  if (shouldOverrideClosingCosts) {
    closingCosts = closingCostsOverride
  }

  // This is valuation, less all fees and reductions
  const feeModifier = 1 - onboardingFee
  const startingInvestment =
    equityOverride ||
    Math.round(
      Math.round(initialValuation * feeModifier) -
        Math.round(initialValuation * closingCosts) -
        Math.round(mortgageAmount) -
        Math.round(rentReduction) -
        Math.round(daysInRemodelDeduction) -
        Math.round(cashTakeout)
    )

  let valuation = initialValuation
  if (isPreliminary) {
    valuation = Math.round((lowerValuationNumber + upperValuationNumber) / 2)
  }
  if (hideValuation) {
    valuation = 500000
  }

  const originalEquityAmount =
    Math.round(valuation * feeModifier) - (mortgageAmount || 0)
  const takeoutAmount =
    cashTakeout || (equityOverride ? originalEquityAmount - equityOverride : 0)

  const caseConfigurations = [
    defaultCaseConfigurations[projectionCase as CaseTypes],
  ]
  const firstYearPerformance = projectMultiCaseFinancialModel({
    propertyValue: valuation,
    equityTakeout: takeoutAmount,
    rentReduction,
    daysInRemodelDeduction,
    onboardingFee,
    closingAndLegalFee: closingCosts,
    capexCosts,
    remainingMortgageAmount: mortgageAmount || 0,
    flockDistributionRate:
      leadUuid === 'ac43df1d-7240-457e-8caa-8c176236bc35'
        ? 0.04
        : DEFAULT_DISTRIBUTION_FACTOR,
    targetContributionDate: new Date('1/1/2024'),
    reinvestDistributions: false,
    caseConfigurations,
    yearsToProject: 1,
    fundEquityPercentTarget: DEFAULT_FUND_EQUITY_PERCENT_TARGET,
    hpaCompounding: CompoundingGrowth.QUARTERLY,
  })

  const firstYearIncome = firstYearPerformance[projectionCase][0].distribution
  const firstYearAppreciation =
    firstYearPerformance[projectionCase][0].appreciation

  return {
    ...props,
    isMobile,
    isTablet,

    firstYearIncome,
    firstYearAppreciation,
    startingInvestment,
    isReinvestmentSelected,
    toggleIsReinvestmentSelected,
    timeHorizonSlider,
    setTimeHorizonSlider,

    handleHighOnClick,
    handleAvgOnClick,
    handleLowOnClick,

    annualAppreciationPercent,
    projectionCase,

    isHighSelected,
    isAvgSelected,
    isLowSelected,

    incomeTooltip,
    appreciationTooltip,
    reinvestDistributionsTooltip,
  }
}

export default useAppreciationPotential
