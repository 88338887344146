import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  ThemeProvider,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  LibraryThemeProvider,
  flockTheme,
  TrackedFooter,
} from '@flock/shared-ui'
import * as Sentry from '@sentry/gatsby'
// @ts-ignore
import TimeMe from 'timeme.js'
import {
  Core_LeadDocument,
  Core_LeadStage,
  Core_TransactionType,
  Core_ValuationType,
  Core_ValuationCategory,
  LandingGetLeadDocument,
  LandingGetValuationDocument,
  LandingPostSlackMessageDocument,
  LandingUpdateLeadDocument,
  LandingGetMostRecentValuationRecordDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  DEFAULT_CLOSING_AND_LEGAL_FEE,
  DEFAULT_ONBOARDING_FEE,
  DEFAULT_PRICE_PER_SHARE,
  formatCityStateZip,
  localStore,
  UserEventType,
} from '@flock/utils'

import { useMutation, useQuery } from '@apollo/client'
import { navigate } from 'gatsby'
import OfferPageWrapper from '../../components/OfferPageComponents/OfferPageWrapper'
import {
  decomposeSlackUrl,
  getSalesAssignee,
  isUuid,
  useRecordPageDuration,
} from '../../components/utils'
import {
  OfferPageContextProvider,
  OfferPageContextType,
} from '../../components/OfferPageComponents/OfferPageContext'
import { identify, shouldTrack, track } from '../../utils/analytics'
import SectionLayout from '../../components/SharedComponents/SectionLayout'
import ErrorCard from '../../components/OfferPageComponents/ErrorCard'

import {
  LeadStatus,
  OfferPageData,
  PrelimOfferPageData,
} from '../../components/OfferPageComponents/offerPageTypes'
import OfferPage from '../../components/OfferPageComponents/OfferPage'
import PreliminaryOfferPage from '../../components/OfferPageComponents/PrelimOfferPageComponents/PreliminaryOfferPage'
import { Address } from '../../components/types'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

// todo: copied from lead_task.go. Figma specifies "condo" type though what is it?
const getPropertyType = (leadAnswers: any) => {
  let propertyType
  const leadAnswerPropertyType = leadAnswers.propertyType
  const { unitDetails } = leadAnswers
  switch (leadAnswerPropertyType) {
    case 'singlefamily':
      propertyType = 'Single-family detached home'
      break
    case 'townhouse':
      propertyType = 'Townhouse'
      break
    case 'condo':
      propertyType = 'Condo'
      break
    case 'multifamily':
      // eslint-disable-next-line no-case-declarations
      const unitCount = unitDetails.length
      switch (unitCount) {
        case 2:
          propertyType = 'Duplex'
          break
        case 3:
          propertyType = 'Triplex'
          break
        case 4:
          propertyType = 'Fourplex'
          break
        default:
          propertyType = 'Multifamily'
          break
      }
      break
    case 'duplex':
      propertyType = 'Duplex'
      break
    case 'triplex':
      propertyType = 'Triplex'
      break
    case 'fourplex':
      propertyType = 'Fourplex'
      break
    default:
      propertyType = ''
  }
  return propertyType
}

type PropertyEstimateProps = {
  params: {
    lead: string
  }
}

const OfferPageV2 = (props: PropertyEstimateProps) => {
  const { params } = props
  const { lead: leadUuid } = params
  useRecordPageDuration()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // TODO: Delete once Jerry Dunn lead page is resolved
  // Slack post: https://flockhomes.slack.com/archives/C02TJA2RRQR/p1681447708805979
  useEffect(() => {
    if (leadUuid === 'f77abc2e-d822-476e-8ad8-fa184d29a757') {
      navigate('/property-estimate/009c0226-e329-4bc1-82d1-4f397b4d0650')
    }
  }, [leadUuid])

  const invalidUuid = typeof window !== 'undefined' && !isUuid(leadUuid)

  const [updateLead] = useMutation(LandingUpdateLeadDocument)
  const [postSlackMessage] = useMutation(LandingPostSlackMessageDocument)
  const [loading, setLoading] = useState<boolean>(true)

  const [pageData, setPageData] = useState<Partial<OfferPageData>>({})
  const [error, setError] = useState(false)
  const { refetch: refetchLead } = useQuery(LandingGetLeadDocument, {
    skip: true,
  })
  const { refetch: refetchValuation } = useQuery(LandingGetValuationDocument, {
    skip: true,
  })
  const { refetch: refetchMostRecentValuationRecord } = useQuery(
    LandingGetMostRecentValuationRecordDocument,
    {
      skip: true,
    }
  )

  const initializePageData = useCallback(async () => {
    let leadDataResult = null
    try {
      const { data: refetchLeadDataResult } = await refetchLead({
        input: {
          leadUuid,
        },
      })
      leadDataResult = refetchLeadDataResult
    } catch (e) {
      setError(true)
      return
    }

    if (
      !leadDataResult?.lead?.lead?.email &&
      !leadDataResult?.lead?.lead?.phoneNumber &&
      localStore?.getItem('disableTracking') !== 'true'
    ) {
      Sentry.captureException(
        new Error(`Offer page viewed for a lead without an email or phone`),
        {
          tags: {
            critical: true,
          },
          extra: {
            leadUuid,
          },
        }
      )
    }

    const customerUuid = leadDataResult?.lead?.lead?.customerUuid
    if (
      shouldTrack() &&
      customerUuid &&
      customerUuid !== '00000000-0000-0000-0000-000000000000' &&
      !localStore?.getItem('customerUuid')
    ) {
      identify({
        userId: customerUuid as string,
      })

      localStore?.setItem('customerUuid', customerUuid)
    }

    const { data: valuationDataResult } = await refetchValuation({
      input: {
        leadUuid,
      },
    })

    const { data: mostRecentValuationRecordDataResult } =
      await refetchMostRecentValuationRecord({
        input: {
          leadUuid,
        },
      })

    const updatedPageData: Partial<OfferPageData> = {}

    const leadData = leadDataResult?.lead?.lead || {}
    const valuationData = valuationDataResult?.valuation?.valuation
    const mostRecentValuationRecordData =
      mostRecentValuationRecordDataResult?.getMostRecentValuationRecord
        ?.valuation

    let parsedJsonAnswers: { [key: string]: any } = {}
    let parsedOverrides: { [key: string]: any } = {}
    if (leadData?.answers) {
      parsedJsonAnswers = JSON.parse(leadData?.answers)
    }
    if (leadData?.overrides) {
      parsedOverrides = JSON.parse(leadData?.overrides)
    }

    // TODO: remove temporarily while DST is paused
    // const isDSTLead =
    //   parsedJsonAnswers.dstLead || leadData.source === 'dst_lead'
    // if (isDSTLead) {
    //   navigate(`/property-estimate/1031/${leadUuid}`)
    // }

    let parsedJsonValuationDetails: { [key: string]: any } = {}
    if (
      valuationData?.valuationDetails &&
      valuationData?.valuationDetails !== 'null'
    ) {
      parsedJsonValuationDetails = JSON.parse(valuationData.valuationDetails)
    }
    const saamValuationType = parsedJsonValuationDetails?.type
    const finalValuation = valuationData?.finalOfferPrice
    const valuationCategory = valuationData?.valuationCategory
    let prelimValuation = 0

    const isPreliminary = finalValuation === 0 || !finalValuation
    let hideValuation = false
    let noValuation = false
    let hiddenSelfReported = false
    let hiddenBrokerReported = false
    let squareFootageOff = false
    let highValuation = false
    let isMultifamily = false

    const {
      adjustedValuation,
      selfReportedValue,
      adjustedValuationUpper,
      adjustedValuationLower,
    } = parsedJsonValuationDetails
    const showRangedValuation =
      ((saamValuationType === Core_ValuationType.ValuationTypeInitial ||
        saamValuationType === Core_ValuationType.ValuationTypeRefresh) &&
        valuationData?.createdAt > '2023-10-17') ||
      adjustedValuationUpper
    const parsedValuation = parsedJsonValuationDetails.valuation
    /* Logic for preliminary valuations
     * Housecanary provides valuationLower, valuation, and valuationUpper. We don't use the lower and upper and create the range ourselves with the valuation
     * We also can have an adjustedValuation based on year built and reported condition. This takes precedence over the valuation. (also used to generate range)
     * Ops can create a range override -> adjustedValuationUpper and adjustedValuationLower.  NOT RELATED TO adjustedValution!!!!!!!!
     * If these are provided, they take precedence over the housecanary valuation and adjustedValuation.
     * This becomes the valuation range and the mean of these becomes the valuation used in the chart.
     */
    if (isPreliminary) {
      // Add most recent valuation, to see if we have rejected it
      updatedPageData.mostRecentValuationPrice =
        mostRecentValuationRecordData?.finalOfferPrice!
      // we try to compute an adjusted valuation on the backend
      // Hide valuation if
      // - there's no manually entered valuation range
      // - there's no prefill valuation
      // - self reported value differs from house canary value by 10%
      // - square footage differs from house canary square footage by 1000 sqft
      let valuationRangeOverrideMedian
      if (adjustedValuationUpper && adjustedValuationLower) {
        valuationRangeOverrideMedian = Math.round(
          (adjustedValuationUpper + adjustedValuationLower) / 2
        )

        updatedPageData.overriddenRange = true
      }
      prelimValuation =
        valuationRangeOverrideMedian || adjustedValuation || parsedValuation
      noValuation = !finalValuation && !prelimValuation
      if (!adjustedValuationUpper && !adjustedValuationLower) {
        hiddenSelfReported =
          Math.abs(prelimValuation - selfReportedValue) > 0.1 * prelimValuation
        hiddenBrokerReported = parsedJsonAnswers.brokerRecommendedOfferPrice
          ? Math.abs(
              prelimValuation - parsedJsonAnswers.brokerRecommendedOfferPrice
            ) >
            0.1 * prelimValuation
          : false
        squareFootageOff =
          parsedJsonAnswers.prefillSquareFootage &&
          Math.abs(
            parsedJsonAnswers.squareFootage -
              parsedJsonAnswers.prefillSquareFootage
          ) > 1000
        highValuation = prelimValuation >= 900000
        isMultifamily = parsedJsonAnswers.propertyType === 'multifamily'

        hideValuation =
          noValuation ||
          hiddenSelfReported ||
          hiddenBrokerReported ||
          squareFootageOff ||
          highValuation ||
          isMultifamily
      }
    }

    const waiveOnboardingFee =
      parsedOverrides.waiveOnboardingFee || parsedJsonAnswers.waiveOnboardingFee
    const feeOverride =
      parsedOverrides.feeOverride || parsedJsonAnswers.feeOverride
    const addressDisplayOverride =
      parsedOverrides.addressDisplayOverride ||
      parsedJsonAnswers.addressDisplayOverride
    const cityStateZipDisplayOverride =
      parsedOverrides.cityStateZipDisplayOverride ||
      parsedJsonAnswers.cityStateZipDisplayOverride
    const equityOverride =
      parsedOverrides.equityOverride || parsedJsonAnswers.equityOverride
    const cashTakeout =
      parsedOverrides.cashTakeout || parsedJsonAnswers.cashTakeout
    const brokerCommission =
      parsedOverrides.brokerCommission || parsedJsonAnswers.brokerCommission
    const closingCostsOverride = parsedOverrides.closingCosts
    const shouldOverrideClosingCosts = parsedOverrides.overrideClosingCosts
    const rentReductionOverride = parsedOverrides.rentReduction
    const daysInRemodelDeductionOverride =
      parsedOverrides.daysInRemodelDeduction

    // Flexible cash flow overrides
    const {
      overrideNetYield,
      overrideHoldingPeriod,
      netYieldOverride,
      holdingPeriodOverride,
    } = parsedOverrides
    const showCashFlowDeductions =
      parsedOverrides.showCashFlowDeductions || false

    if (overrideNetYield) {
      updatedPageData.netYieldOverride = netYieldOverride
    }
    if (overrideHoldingPeriod) {
      updatedPageData.holdingPeriodOverride = holdingPeriodOverride
    }

    updatedPageData.showCashFlowDeductions = showCashFlowDeductions

    const {
      capexCosts,
      leadStatusOverride,
      overrideLeadStatus,
      leadNameOverride,
      overrideLeadName,
      portfolioName,
      portfolioDoorCount,
      portfolioAssetCount,
      portfolioCityState,
      portfolioSFRDoors,
      portfolioMultifamilyDoors,
    } = parsedOverrides

    updatedPageData.portfolioName = portfolioName
    updatedPageData.portfolioDoorCount = portfolioDoorCount
    updatedPageData.portfolioAssetCount = portfolioAssetCount
    updatedPageData.portfolioCityState = portfolioCityState
    updatedPageData.portfolioSFRDoors = portfolioSFRDoors
    updatedPageData.portfolioMultifamilyDoors = portfolioMultifamilyDoors

    const staticDocumentOverrides = parsedOverrides.documents || {}

    let onboardingFee = DEFAULT_ONBOARDING_FEE
    if (waiveOnboardingFee) {
      onboardingFee = 0
    } else if (feeOverride) {
      onboardingFee = feeOverride
    }

    const hasCityStateZipcodeOverride =
      cityStateZipDisplayOverride !== undefined

    updatedPageData.leadStage = leadData.leadStage
    updatedPageData.fullName = leadData.fullName || ''
    updatedPageData.transactionType = leadData.transactionType as string

    const displayFullName = updatedPageData.fullName.replace(' Unknown', '')
    updatedPageData.fullName = displayFullName
    if (overrideLeadName) {
      updatedPageData.fullName = leadNameOverride
      updatedPageData.leadNameOverride = leadNameOverride
    }

    updatedPageData.customerUuid = customerUuid as string
    updatedPageData.email = leadData.email as string
    updatedPageData.phoneNumber = leadData.phoneNumber as string
    updatedPageData.isOverriddenAddress = !!addressDisplayOverride

    updatedPageData.street = addressDisplayOverride || leadData.address?.street
    updatedPageData.state = leadData.address?.state as string
    updatedPageData.city = leadData.address?.city as string
    updatedPageData.zipcode = leadData.address?.zipcode as string
    updatedPageData.latitude = leadData.address?.latitude as number
    updatedPageData.longitude = leadData.address?.longitude as number
    const cityStateZip = formatCityStateZip(leadData?.address as Address)
    updatedPageData.cityStateZipcode =
      cityStateZipDisplayOverride || cityStateZip
    updatedPageData.hasCityStateZipcodeOverride = hasCityStateZipcodeOverride

    updatedPageData.bedrooms = parsedJsonAnswers.bedCount
    updatedPageData.bathrooms = parsedJsonAnswers.bathCount
    updatedPageData.halfBaths = parsedJsonAnswers.halfBathCount

    const hasUnitDetails = parsedJsonAnswers.unitDetails?.length > 0
    if (hasUnitDetails) {
      let summedBedrooms = 0
      let summedBathrooms = 0
      parsedJsonAnswers.unitDetails.forEach((details: any) => {
        summedBedrooms += Number(details?.bedCount || details?.bedrooms || 0)
        summedBathrooms += Number(
          details?.bathCount || details?.full_bathrooms || 0
        )
      })
      updatedPageData.bedrooms = summedBedrooms
      updatedPageData.bathrooms = summedBathrooms
    }
    updatedPageData.propertyCondition = parsedJsonAnswers.propertyCondition
    updatedPageData.mortgage = parsedJsonAnswers.mortgageAmount
    updatedPageData.contributionAgreementRequested =
      parsedJsonAnswers.contributionAgreementRequested
    updatedPageData.scheduleInspectionRequested =
      parsedJsonAnswers.scheduleInspectionRequested
    updatedPageData.refreshOfferRequested =
      parsedJsonAnswers.refreshOfferRequested
    updatedPageData.propertyTypeDisplayString =
      getPropertyType(parsedJsonAnswers)

    // if there's no manual range inputted, we calculate the range based off of the prelim valuation
    updatedPageData.offerLow =
      adjustedValuationLower ||
      Math.round(prelimValuation * 0.925) ||
      Math.trunc(((finalValuation as number) * 0.96) / 1000) * 1000 // trunc rounds down to the nearest 1000
    updatedPageData.offerHigh =
      adjustedValuationUpper ||
      Math.round(prelimValuation * 1.075) ||
      Math.ceil(((finalValuation as number) * 1.04) / 1000) * 1000 // round up to the nearest 1000
    updatedPageData.showRangedValuation = showRangedValuation
    updatedPageData.hideValuation = hideValuation
    updatedPageData.isPreliminary = isPreliminary
    updatedPageData.uwCashOnCashYield =
      parsedOverrides?.netYieldOverride ||
      parsedJsonValuationDetails.outputs?.uwCashOnCashYield
        ?.customerUwCashOnCashYield
    // set offer price
    updatedPageData.finalOffer = showRangedValuation
      ? (updatedPageData.offerLow! + updatedPageData.offerHigh!) / 2
      : (finalValuation as number)
    updatedPageData.prelimOffer = prelimValuation

    const expiryDate = new Date(valuationData?.expiresAt)
    updatedPageData.expiryDate = expiryDate as Date
    // for offer pages that are older - we dont want to show cash flow anymore
    // they can change at initial underwrite again - and for final offers we dont want them to see
    // the bps of cash flow - they should reference their CA for what they get
    const isExpired = new Date().getTime() > expiryDate.getTime()

    updatedPageData.hideNetYield =
      updatedPageData.uwCashOnCashYield === undefined ||
      updatedPageData.uwCashOnCashYield < 0.02 ||
      isExpired

    // set overrides
    updatedPageData.portfolioDrawerContent! = {
      propertyValue: finalValuation || prelimValuation,
      mortgageAmount: parsedJsonAnswers.mortgageAmount || 0,
      capexCosts: capexCosts || 0,
      equityOverride: equityOverride || 0,
      shouldOverrideClosingCosts,
      closingCostsOverride,
      cashTakeout: cashTakeout || 0,
      onboardingFee,
      brokerCommission,
      rentReduction: 0,
    }

    updatedPageData.shouldOverrideLeadStatus = overrideLeadStatus
    updatedPageData.leadStatusOverride = leadStatusOverride

    // new submarket rent deduction on outputs. Legacy valuations have it computed from the inputs.
    const submarketRentDeduction =
      parsedJsonValuationDetails.outputs?.submarketRentDeduction ||
      (parsedJsonValuationDetails.inputs?.marketRent?.currentlyOccupied &&
      parsedJsonValuationDetails.inputs?.marketRent?.analystProjectedRent >
        parsedJsonValuationDetails.inputs?.marketRent?.currentRent
        ? (parsedJsonValuationDetails.inputs?.marketRent?.analystProjectedRent -
            parsedJsonValuationDetails.inputs?.marketRent?.currentRent) *
          parsedJsonValuationDetails.inputs?.marketRent?.monthsRemainingOnLease
        : 0)

    updatedPageData.portfolioDrawerContent.rentReduction =
      rentReductionOverride ??
      (saamValuationType === Core_ValuationType.ValuationTypeFinal
        ? submarketRentDeduction
        : 0)

    const feeModifier = 1 - onboardingFee

    const originalEquityAmount =
      Math.round(
        updatedPageData.portfolioDrawerContent.propertyValue * feeModifier
      ) - updatedPageData.portfolioDrawerContent.mortgageAmount

    const takeoutAmount =
      cashTakeout ||
      (equityOverride ? originalEquityAmount - equityOverride : 0)

    let closingAndLegalTotal =
      DEFAULT_CLOSING_AND_LEGAL_FEE *
      updatedPageData.portfolioDrawerContent.propertyValue

    if (shouldOverrideClosingCosts) {
      closingAndLegalTotal =
        closingCostsOverride *
        updatedPageData.portfolioDrawerContent.propertyValue
    }

    const daysInRemodelDeduction =
      parsedJsonValuationDetails.outputs?.daysInRemodelDeduction ||
      parsedJsonValuationDetails.inputs?.remodelCost?.daysInRemodelDeduction ||
      0

    updatedPageData.portfolioDrawerContent!.daysInRemodelDeduction =
      saamValuationType === Core_ValuationType.ValuationTypeFinal
        ? daysInRemodelDeduction
        : 0

    if (
      daysInRemodelDeductionOverride !== undefined &&
      daysInRemodelDeductionOverride !== null
    ) {
      updatedPageData.portfolioDrawerContent!.daysInRemodelDeduction =
        daysInRemodelDeductionOverride
    }

    updatedPageData.valuationCategory = valuationCategory
    if (
      updatedPageData.portfolioDrawerContent!.daysInRemodelDeduction &&
      updatedPageData.portfolioDrawerContent!.rentReduction &&
      updatedPageData.transactionType !==
        Core_TransactionType.TransactionTypePortfolio_721 &&
      updatedPageData.valuationCategory !==
        Core_ValuationCategory.ValuationCategoryMultiFamily
    ) {
      Sentry.captureException(
        new Error(
          `Offer page for ${updatedPageData.fullName} has both a submarket rent and days in reno deduction. It should only have one`
        ),
        {
          extra: {
            leadUuid,
          },
        }
      )
    }

    const equityAmount =
      originalEquityAmount -
      takeoutAmount -
      updatedPageData.portfolioDrawerContent.capexCosts -
      closingAndLegalTotal -
      updatedPageData.portfolioDrawerContent.rentReduction -
      updatedPageData.portfolioDrawerContent.daysInRemodelDeduction!
    let shareCount = equityAmount / DEFAULT_PRICE_PER_SHARE
    updatedPageData.equityAmount = equityAmount

    // offer portfolio section checks if shareCount < 0
    if (Number.isNaN(shareCount)) {
      shareCount = -1
    }
    updatedPageData.shareCount = shareCount

    updatedPageData.equityOverride = equityOverride
    updatedPageData.shouldOverrideClosingCosts = shouldOverrideClosingCosts
    updatedPageData.closingCostsOverride = closingCostsOverride
    updatedPageData.cashTakeout = cashTakeout
    updatedPageData.capexCosts = capexCosts

    updatedPageData.saamValuationType = saamValuationType

    let isAgentSample = false
    if (typeof window !== 'undefined') {
      const { search } = window.location
      const searchParams = new URLSearchParams(search)
      isAgentSample = searchParams.get('agentSample') === 'true'
    }
    const salesAssigneeData = getSalesAssignee(
      leadData?.customer?.operator,
      isAgentSample
    )
    updatedPageData.offerPageContext = {
      ...salesAssigneeData,
      user: {
        fullName: leadData.fullName as string,
        email: leadData.email as string,
        phoneNumber: leadData.phoneNumber as string,
        slackThreadUrl: leadData.slackThreadUrl as string,
      },
    }

    updatedPageData.staticDocumentOverrides = staticDocumentOverrides

    if (leadData?.documents?.length) {
      updatedPageData.leadDocuments = leadData.documents as Core_LeadDocument[]
    }

    const updateMortgage = async (newMortgage: number) => {
      if (parsedJsonAnswers) {
        parsedJsonAnswers.mortgageAmount = newMortgage
        try {
          await updateLead({
            variables: {
              updateLeadInput: {
                leadUuid,
                answers: JSON.stringify(parsedJsonAnswers),
              },
            },
            refetchQueries: [LandingGetLeadDocument],
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
    updatedPageData.updateMortgage = updateMortgage

    const updateScheduleInspectionRequested = async (
      scheduleInspectionRequested: boolean
    ) => {
      if (parsedJsonAnswers) {
        parsedJsonAnswers.scheduleInspectionRequested =
          scheduleInspectionRequested
        try {
          await updateLead({
            variables: {
              updateLeadInput: {
                leadUuid,
                answers: JSON.stringify(parsedJsonAnswers),
              },
            },
            refetchQueries: [LandingGetLeadDocument],
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
    updatedPageData.updateScheduleInspectionRequested =
      updateScheduleInspectionRequested

    const updateRefreshOfferRequested = async (refreshOfferRequested: Date) => {
      if (parsedJsonAnswers) {
        parsedJsonAnswers.refreshOfferRequested = refreshOfferRequested
        try {
          await updateLead({
            variables: {
              updateLeadInput: {
                leadUuid,
                answers: JSON.stringify(parsedJsonAnswers),
              },
            },
            refetchQueries: [LandingGetLeadDocument],
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
    updatedPageData.updateRefreshOfferRequested = updateRefreshOfferRequested

    const updateContributionAgreementRequested = async (
      contributionAgreementRequested: boolean
    ) => {
      if (parsedJsonAnswers) {
        parsedJsonAnswers.contributionAgreementRequested =
          contributionAgreementRequested
        try {
          await updateLead({
            variables: {
              updateLeadInput: {
                leadUuid,
                answers: JSON.stringify(parsedJsonAnswers),
              },
            },
            refetchQueries: [LandingGetLeadDocument],
          })
        } catch (e) {
          console.error(e)
        }
      }
    }
    updatedPageData.updateContributionAgreementRequested =
      updateContributionAgreementRequested

    // set tracking
    const notifySlack = async (stage: string, hiddenReason?: string) => {
      if (shouldTrack()) {
        const hotjarUrl = `https://insights.hotjar.com/sites/2547595/playbacks?sort_by=created&filters=%7B%22AND%22:%5B%7B%22DAYS_AGO%22:%7B%22created%22:7%7D%7D%2C%7B%22CONTAINS%22:%7B%22all_page_paths%22:%22${leadUuid}%22%7D%7D%5D%7D`
        let message = ''
        if (stage === 'hidden') {
          message = `Lead viewed the hidden valuation offer page. The valuation was hidden because ${hiddenReason} (<${hotjarUrl}|Hotjar>) cc: <@${salesAssigneeData.slackId}>`
        } else if (stage === `preliminary`) {
          message = `Lead viewed the preliminary offer page valuation. (<${hotjarUrl}|Hotjar>) cc: <@${salesAssigneeData.slackId}>`
          if (
            leadData.createdAt &&
            !adjustedValuationUpper &&
            !adjustedValuationLower
          ) {
            const createdAtDate = new Date(leadData.createdAt)
            const now = new Date()
            if (now.getTime() - createdAtDate.getTime() > 86400000) {
              Sentry.captureException(
                new Error(
                  `24 hours have passed since lead was created and a valuation still has not been created.`
                ),
                {
                  extra: {
                    leadUuid,
                  },
                }
              )
            }
          }
        } else if (stage === `final`) {
          message = `Lead viewed the final valuation page. (<${hotjarUrl}|Hotjar>) cc: <@${salesAssigneeData.slackId}>`
        }

        if (message) {
          try {
            const { channel, threadTimestamp } = decomposeSlackUrl(
              leadData.slackThreadUrl as string
            )
            await postSlackMessage({
              variables: {
                postSlackMessageInput: {
                  channel,
                  threadTimestamp,
                  text: message,
                },
              },
            })
          } catch (e) {
            console.error(e)
          }
        }
      }
    }

    let hiddenReason = ''
    if (noValuation) {
      hiddenReason = 'there was no prefill valuation from HouseCanary.'
    }

    if (hiddenSelfReported) {
      hiddenReason =
        'the self reported value was more than 10% off from the HouseCanary valuation.'
    }
    if (squareFootageOff) {
      hiddenReason =
        'the reported square footage was more than 10% off of the HouseCanary square footage.'
    }
    if (highValuation) {
      hiddenReason = 'the HouseCanary valuation was higher than 900000.'
    }
    if (isMultifamily) {
      hiddenReason = 'the property is a multifamily property'
    }
    let stage = 'unknown'
    if (leadData?.slackThreadUrl) {
      updatedPageData.slackThreadUrl = leadData.slackThreadUrl
      if (valuationData) {
        stage = 'preliminary'
        if (hideValuation) {
          stage = 'hidden'
          track('hidden-property-estimate-viewed', {
            offerPageStatus: 'hidden',
            pageViewed: TimeMe.currentPageName,
            actionType: UserEventType.PAGE_DURATION,
          })
        } else if (isPreliminary) {
          stage = 'preliminary'
          track('preliminary-property-estimate-viewed', {
            offerPageStatus: 'preliminary',
            pageViewed: TimeMe.currentPageName,
            actionType: UserEventType.PAGE_DURATION,
          })
        } else {
          stage = 'final'
          track('final-property-estimate-viewed', {
            offerPageStatus: 'final',
            pageViewed: TimeMe.currentPageName,
            saamValuationType,
            showRangedValuation,
            actionType: UserEventType.PAGE_DURATION,
          })
        }
        notifySlack(stage, hiddenReason)
      }
    }

    // Set lead status
    let inspectionDate = null
    if (
      leadData.inspectionDate &&
      new Date(leadData.inspectionDate).getFullYear() > 2000
    ) {
      inspectionDate = new Date(leadData.inspectionDate)
      updatedPageData.inspectionDate = inspectionDate
    }

    let leadStatus = LeadStatus.DISCUSS_YOUR_OFFER
    if (updatedPageData.shouldOverrideLeadStatus) {
      leadStatus = leadStatusOverride
    } else if (
      leadData.leadStage === Core_LeadStage.LeadStageContributionSigned
    ) {
      leadStatus = LeadStatus.CLOSING_PROCESSES
    } else if (leadData.leadStage === Core_LeadStage.LeadStageAgreementSent) {
      leadStatus = LeadStatus.SIGN_CONTRIBUTION_AGREEMENT
    } else if (
      parsedJsonValuationDetails?.type === Core_ValuationType.ValuationTypeFinal
    ) {
      leadStatus = LeadStatus.REVIEW_FINAL_OFFER
    } else if (inspectionDate) {
      if (inspectionDate.getTime() < new Date().getTime()) {
        leadStatus = LeadStatus.SCHEDULE_AN_INSPECTION_POST_INSPECTION
      } else {
        leadStatus = LeadStatus.SCHEDULE_AN_INSPECTION_BOOKED
      }
    } else {
      leadStatus = LeadStatus.DISCUSS_YOUR_OFFER
    }

    updatedPageData.leadStatus = leadStatus

    setPageData(updatedPageData)
    setLoading(false)
  }, [
    leadUuid,
    postSlackMessage,
    refetchLead,
    refetchValuation,
    updateLead,
    refetchMostRecentValuationRecord,
  ])

  const { offerPageContext } = pageData

  useEffect(() => {
    if (!invalidUuid) {
      initializePageData()
    }
  }, [initializePageData, invalidUuid])

  if (invalidUuid || error) {
    return (
      <ThemeProvider theme={flockTheme}>
        <LibraryThemeProvider>
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            id="calendlyPopupRoot"
            sx={{ backgroundColor: 'trustBlue.main', overflow: 'hidden' }}
          >
            <ErrorCard text="Please try refreshing the page or schedule a call with us to go over your estimated valuation." />
          </Box>
        </LibraryThemeProvider>
      </ThemeProvider>
    )
  }

  return (
    <>
      <OfferPageWrapper leadUuid={leadUuid}>
        {loading ? (
          <Box
            height="auto"
            pt="50px"
            sx={{ backgroundColor: 'trustBlue.main' }}
          >
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <OfferPageContextProvider
            value={offerPageContext as OfferPageContextType}
          >
            {pageData.isPreliminary && !pageData.overriddenRange ? (
              <Box
                height="auto"
                position="relative"
                id="calendlyPopupRoot"
                sx={{ backgroundColor: 'trustBlue.main' }}
              >
                <PreliminaryOfferPage
                  leadUuid={leadUuid}
                  pageData={pageData as PrelimOfferPageData}
                />
              </Box>
            ) : (
              <Box
                height="auto"
                pt={isMobile ? '0px' : '50px'}
                id="calendlyPopupRoot"
                sx={{ backgroundColor: 'trustBlue.main' }}
              >
                <OfferPage
                  pageData={pageData as OfferPageData}
                  leadUuid={leadUuid}
                />
              </Box>
            )}
          </OfferPageContextProvider>
        )}

        <SectionLayout name="footer" backgroundColor="gray1.main">
          <Grid item xs={12}>
            <TrackedFooter />
          </Grid>
        </SectionLayout>
      </OfferPageWrapper>
    </>
  )
}

export default OfferPageV2
