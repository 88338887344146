import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const MultifamilyIconIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 16}
      height={height || 18}
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.34375H14.4062V3.76562H13.56L11.6538 1.59558C11.3729 1.27586 10.8746 1.27696 10.5952 1.59794L8.70805 3.76562H7.32809L5.4012 1.59148C5.12142 1.2758 4.62858 1.27579 4.3488 1.59148L2.42191 3.76562H1.59375V1.34375H8ZM1.59375 4.85938V16.6562H2.375V13.375C2.375 11.9943 3.49429 10.875 4.875 10.875C6.25571 10.875 7.375 11.9943 7.375 13.375V16.6562H8.625V13.375C8.625 11.9943 9.74429 10.875 11.125 10.875C12.5057 10.875 13.625 11.9943 13.625 13.375V16.6562H14.4062V4.85938H13.3832C13.1809 4.85938 12.9885 4.77226 12.855 4.62029L11.1268 2.65301L9.41621 4.61793C9.28267 4.77132 9.08926 4.85938 8.88589 4.85938H7.15234C6.95114 4.85938 6.75958 4.77318 6.62613 4.62261L4.875 2.64678L3.12387 4.62261C2.99042 4.77318 2.79886 4.85938 2.59766 4.85938H1.59375ZM9.09375 17.75H6.90625H2.84375H0.96875C0.709867 17.75 0.5 17.5401 0.5 17.2812V0.71875C0.5 0.459867 0.709867 0.25 0.96875 0.25H8H15.0312C15.2901 0.25 15.5 0.459867 15.5 0.71875V17.2812C15.5 17.5401 15.2901 17.75 15.0312 17.75H13.1562H9.09375ZM6.28125 13.375V16.6562H3.46875V13.375C3.46875 12.5983 4.09835 11.9688 4.875 11.9688C5.65165 11.9688 6.28125 12.5983 6.28125 13.375ZM12.5312 16.6562V13.375C12.5312 12.5983 11.9017 11.9688 11.125 11.9688C10.3483 11.9688 9.71875 12.5983 9.71875 13.375V16.6562H12.5312ZM4.09375 7.90625V6.34375H5.65625V7.90625H4.09375ZM3 5.71875C3 5.45987 3.20987 5.25 3.46875 5.25H6.28125C6.54013 5.25 6.75 5.45987 6.75 5.71875V8.53125C6.75 8.79013 6.54013 9 6.28125 9H3.46875C3.20987 9 3 8.79013 3 8.53125V5.71875ZM10.3438 6.34375V7.90625H11.9062V6.34375H10.3438ZM9.71875 5.25C9.45987 5.25 9.25 5.45987 9.25 5.71875V8.53125C9.25 8.79013 9.45987 9 9.71875 9H12.5312C12.7901 9 13 8.79013 13 8.53125V5.71875C13 5.45987 12.7901 5.25 12.5312 5.25H9.71875Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default MultifamilyIconIcon
