import { DEFAULT_CLOSING_AND_LEGAL_FEE } from '@flock/utils'
import { useMediaQuery, useTheme } from '@mui/material'
import { OfferExchangeProps } from './offerExchangeTypes'

const useOfferExchange = (props: OfferExchangeProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const {
    finalOffer,
    mortgage,
    daysInRemodelDeduction,
    onboardingFee,
    shouldOverrideClosingCosts,
    closingCostsOverride,
    rentReduction,
    cashTakeout,
    equityOverride,
  } = props

  let closingCosts = DEFAULT_CLOSING_AND_LEGAL_FEE
  if (shouldOverrideClosingCosts) {
    closingCosts = closingCostsOverride
  }

  // This is valuation, less all fees and reductions
  const feeModifier = 1 - onboardingFee
  const equityValue =
    equityOverride ||
    Math.round(
      Math.round(finalOffer * feeModifier) -
        Math.round(finalOffer * closingCosts) -
        Math.round(mortgage) -
        Math.round(rentReduction) -
        Math.round(daysInRemodelDeduction) -
        Math.round(cashTakeout)
    )

  return {
    ...props,
    equityValue,
    closingCosts,

    isMobile,
    isTablet,
  }
}

export default useOfferExchange
