import { InfoIcon } from '@flock/shared-ui'
import { Box, Paper, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { OfferExchangePresentationalProps } from './offerExchangeTypes'

const OfferExchangeTablet = (props: OfferExchangePresentationalProps) => {
  const {
    finalOffer,
    mortgage,
    cashTakeout,
    rentReduction,
    daysInRemodelDeduction,
    brokerCommission,
    onboardingFee,
    closingCosts,
    equityValue,
    showRangedValuation,
  } = props

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Box>
        <Paper
          sx={{
            padding: '16px',
            backgroundColor: 'gray1.main',
            borderRadius: '8px',
          }}
        >
          {/* Offer Price */}
          <Box display="flex" flexDirection="column" gap="8px">
            <Box
              display="flex"
              flexDirection="row"
              gap="8px"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Box
                display="flex"
                flexDirection="row"
                gap="8px"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Typography variant="p2" sx={{ fontWeight: 500 }}>
                  Valuation
                </Typography>
                {showRangedValuation ? (
                  <Tooltip
                    title="Valuation assumes the midpoint of the range."
                    arrow
                    placement="bottom"
                    enterTouchDelay={0}
                  >
                    <Box
                      sx={{
                        display: 'inline',
                      }}
                    >
                      <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                    </Box>
                  </Tooltip>
                ) : null}
              </Box>
              <Typography variant="p2" sx={{ fontWeight: 500 }}>
                ${finalOffer?.toLocaleString()}
              </Typography>
            </Box>

            {/* Mortgage */}
            {mortgage ? (
              <Box
                display="flex"
                flexDirection="row"
                gap="8px"
                justifyContent="space-between"
                alignItems="baseline"
                ml="16px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="p2">Mortgage balance</Typography>
                  <Tooltip
                    title="Flock will pay off any existing mortgage at closing."
                    arrow
                    placement="right"
                    enterTouchDelay={0}
                  >
                    <Box
                      sx={{
                        display: 'inline',
                      }}
                    >
                      <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant="p2">
                  -${Math.round(mortgage).toLocaleString()}
                </Typography>
              </Box>
            ) : null}

            <Box
              display="flex"
              flexDirection="row"
              gap="8px"
              justifyContent="space-between"
              alignItems="baseline"
              ml="16px"
            >
              <Typography variant="p2">
                Onboarding fee (
                {((onboardingFee - brokerCommission) * 100).toLocaleString()}
                %)
              </Typography>
              <Typography variant="p2">
                -$
                {Math.round(
                  finalOffer * (onboardingFee - brokerCommission)
                ).toLocaleString()}
              </Typography>
            </Box>

            {brokerCommission ? (
              <Box
                display="flex"
                flexDirection="row"
                gap="8px"
                justifyContent="space-between"
                alignItems="baseline"
                ml="16px"
              >
                <Typography variant="p2">
                  Broker commission ({(brokerCommission * 100).toLocaleString()}
                  %)
                </Typography>
                <Typography variant="p2">
                  -$
                  {Math.round(finalOffer * brokerCommission).toLocaleString()}
                </Typography>
              </Box>
            ) : null}

            <Box
              display="flex"
              flexDirection="row"
              gap="8px"
              justifyContent="space-between"
              alignItems="baseline"
              ml="16px"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="2px"
              >
                <Typography variant="p2">
                  Title & closing costs ({(closingCosts * 100).toLocaleString()}
                  %)
                </Typography>
                <Tooltip
                  title="These costs may vary and will be finalized by the title company."
                  arrow
                  placement="right"
                  enterTouchDelay={0}
                >
                  <Box
                    sx={{
                      display: 'inline',
                    }}
                  >
                    <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                  </Box>
                </Tooltip>
              </Box>
              <Typography variant="p2">
                -${Math.round(closingCosts * finalOffer).toLocaleString()}
              </Typography>
            </Box>

            {rentReduction ? (
              <Box
                display="flex"
                flexDirection="row"
                gap="8px"
                justifyContent="space-between"
                alignItems="baseline"
                ml="16px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="p2">Rent reduction</Typography>
                  <Tooltip
                    title="Because your property’s existing lease is below fair market rent, Flock adjusts for the income lost through the remainder of its term."
                    arrow
                    placement="right"
                    enterTouchDelay={0}
                  >
                    <Box
                      sx={{
                        display: 'inline',
                      }}
                    >
                      <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant="p2">
                  -${rentReduction.toLocaleString()}
                </Typography>
              </Box>
            ) : null}

            {/* Days in Remodel Deduction */}
            {daysInRemodelDeduction ? (
              <Box
                display="flex"
                flexDirection="row"
                gap="8px"
                justifyContent="space-between"
                alignItems="baseline"
                ml="16px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="p2">
                    Days in remodel deduction
                  </Typography>
                  <Tooltip
                    title="Because your property will undergo a remodeling process, Flock adjusts for the income lost during this time."
                    arrow
                    placement="right"
                    enterTouchDelay={0}
                  >
                    <Box
                      sx={{
                        display: 'inline',
                      }}
                    >
                      <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                    </Box>
                  </Tooltip>
                </Box>
                <Typography variant="p2">
                  -${Math.round(daysInRemodelDeduction).toLocaleString()}
                </Typography>
              </Box>
            ) : null}

            {/* Cash Takeout */}
            {cashTakeout ? (
              <Box
                display="flex"
                flexDirection="row"
                gap="8px"
                justifyContent="space-between"
                alignItems="baseline"
                ml="16px"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="2px"
                >
                  <Typography variant="p2">
                    {cashTakeout < 0 ? 'Cash investment' : 'Cash out amount'}
                  </Typography>
                  {cashTakeout > 0 ? (
                    <Tooltip
                      title="Any cash that you receive at close will be treated as a traditional taxable amount of income."
                      arrow
                      placement="right"
                      enterTouchDelay={0}
                    >
                      <Box
                        sx={{
                          display: 'inline',
                        }}
                      >
                        <InfoIcon fill="#B6BABE" width="12px" height="12px" />
                      </Box>
                    </Tooltip>
                  ) : null}
                </Box>
                <Typography variant="p2">
                  {cashTakeout < 0 ? '+' : '-'}$
                  {cashTakeout < 0
                    ? (-1 * cashTakeout).toLocaleString()
                    : cashTakeout.toLocaleString()}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </Paper>
      </Box>

      {/* Equity Value */}
      <Box>
        <Paper
          sx={{
            padding: '16px',
            backgroundColor: 'green0.main',
            borderRadius: '8px',
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="p2" sx={{ fontWeight: 600 }}>
              Your starting account value
            </Typography>
            <Typography variant="p2" sx={{ fontWeight: 600 }}>
              {equityValue < 0
                ? `-$${Math.round(equityValue * -1).toLocaleString()}`
                : `$${Math.round(equityValue).toLocaleString()}`}
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Box>
  )
}

export default OfferExchangeTablet
