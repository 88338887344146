import { useMediaQuery, useTheme } from '@mui/material'
import { OfferQuestionsPresentationalProps } from './offerQuestionsTypes'

const useOfferQuestions: () => OfferQuestionsPresentationalProps = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const offerQuestions = [
    {
      title: 'Why Flock instead of selling my property?',
      text: 'While both options offer an exit from your rental properties, selling could lose you a substantial portion of your gains to taxes and fees, not to mention losing regular rental income. Joining Flock through the 721 exchange does not trigger capital gains taxes and allows you to continue earning real estate income and potential equity appreciation.',
    },
    {
      title: 'Why Flock instead of hiring a property manager?',
      text: 'A good property manager can offer some relief from your rentals, but as long as you hold title, you are financially and legally liable. This includes small items like vacancies, repairs, and evictions, but also big items like large renovations and disasters. By having ownership in Flock’s fund, your risk is diversified across hundreds of institutionally managed homes - you’re not liable for any single property. Our team manages everything, including leasing, repairs, and HOA payments, for every home in the fund. Because we are accountable to the long-term performance of the fund’s homes, our incentives are also closely aligned with yours. ',
    },
    {
      title: 'What happens to my property after I join Flock?',
      text: `Title of the rental property is transferred to Flock’s fund, of which you are now an investor. We operate all homes in the fund as long-term rentals – we don't flip or redevelop them. After closing, we will perform necessary value-add renovations, repairs, or maintenance to maximize its long-term earning potential. We will honor the existing lease if the home is occupied. The fund will own and operate rental property as long as they are accretive to the fund.`,
    },
    {
      title: 'How is my cash flow paid?',
      text: 'If you elect to receive your quarterly cash flow directly, it will be deposited in your bank account. Cash flow from Flock is generally non-taxable when paid out because Flock is a partnership. Not taking cash flow instead allows for future compounding of returns.',
    },
    {
      title: 'What is the general tax treatment of Flock income?',
      text: 'As a Flock Client, you’re taxed on your share of fund annual income reported to you via Schedule K-1. Flock prepares these documents for you and delivers them to you by April 1st with instructions. Most Flock clients get to claim a lower taxable income than previously as landlords because Flock passes through a greater amount of interest expense, loan setup costs, and depreciation on new capital improvements.',
    },
    {
      title: 'Does Flock Homes offer cash at close?',
      text: 'While we are not a cash buyer, we do offer limited cash at close on a case-by-case basis. Schedule a call with one of our real estate advisors to discuss your needs.',
    },
    {
      title: 'How quickly can Flock close? ',
      text: 'The entire transaction is completed in-house and can close in as soon as 2-4 weeks. Unlike in a 1031 exchange, the 721 exchange process does not require a qualified intermediary and is not subject to any acquisition timeline constraints. Schedule a call with one of our real estate advisors to learn more about the process.',
    },
  ]

  return {
    isMobile,
    isTablet,

    offerQuestions,
  }
}

export default useOfferQuestions
