import { TrackedButton, ProgressIndicator } from '@flock/shared-ui'
import {
  Box,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import {
  Core_TransactionType,
  LandingPostSlackMessageDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'
import { Close } from '@mui/icons-material'
import { decomposeSlackUrl, getTimeForZip, useCalendly } from '../utils'
import ScheduleCall from '../SharedComponents/ScheduleCall'
import OfferPageContext from './OfferPageContext'
import { INVESTOR_URL } from '../../constants'
import { LeadStatus } from './offerPageTypes'

const leadStatuses = [
  LeadStatus.GET_AN_OFFER,
  LeadStatus.DISCUSS_YOUR_OFFER,
  LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED,
  LeadStatus.SCHEDULE_AN_INSPECTION_BOOKED,
  LeadStatus.SCHEDULE_AN_INSPECTION_POST_INSPECTION,
  LeadStatus.REVIEW_FINAL_OFFER,
  LeadStatus.SIGN_CONTRIBUTION_AGREEMENT,
  LeadStatus.CLOSING_PROCESSES,
]

type ProgressIndicatorWithExpirationProps = {
  label: string
  statuses: LeadStatus[]
  currentStatus: LeadStatus
}

const ProgressIndicatorWithExpiration = (
  props: ProgressIndicatorWithExpirationProps
) => {
  const { label, currentStatus, statuses } = props
  let displayedStatus: 'next' | 'complete' | 'current' = 'next'
  const isFuture = statuses.every(
    (status) =>
      leadStatuses.indexOf(status) > leadStatuses.indexOf(currentStatus)
  )
  const isPast = statuses.every(
    (status) =>
      leadStatuses.indexOf(status) < leadStatuses.indexOf(currentStatus)
  )

  if (isFuture) {
    displayedStatus = 'next'
  } else if (isPast) {
    displayedStatus = 'complete'
  } else {
    displayedStatus = 'current'
  }

  return <ProgressIndicator text={label} status={displayedStatus} />
}

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '500px',
  width: '70%',
  maxHeight: '75vh',
  height: '240px',
  overflowY: 'auto',
  padding: '36px',
  borderRadius: '16px',
})

type ProgressBarDisplayProps = {
  isExpired: boolean
  leadStatus: LeadStatus
  inspectionDate?: Date
  zipcode?: string
  leadUuid: string
  transactionType: string
  scheduleInspectionRequested: boolean
  updateScheduleInspectionRequested: (requested: boolean) => void
  contributionAgreementRequested: boolean
  updateContributionAgreementRequested: (requested: boolean) => void
  updateRefreshOfferRequested: (requested: Date) => void
  refreshOfferRequested: Date
}

const ProgressBarDisplay = (props: ProgressBarDisplayProps) => {
  const {
    isExpired,
    leadStatus,
    inspectionDate,
    zipcode,
    leadUuid,
    transactionType,
    scheduleInspectionRequested,
    updateScheduleInspectionRequested,
    contributionAgreementRequested,
    updateContributionAgreementRequested,
    updateRefreshOfferRequested,
    refreshOfferRequested,
  } = props

  const currentStatus = isExpired ? LeadStatus.DISCUSS_YOUR_OFFER : leadStatus

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const context = useContext(OfferPageContext)
  const [postSlackMessage] = useMutation(LandingPostSlackMessageDocument)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [inspectionRequested, setInspectionRequested] = useState<boolean>(
    scheduleInspectionRequested
  )
  const [caRequested, setCaRequested] = useState<boolean>(
    contributionAgreementRequested
  )
  const [refreshRequested, setRefreshRequested] = useState<Date>(
    refreshOfferRequested
  )

  const isDateWithin7Days = (inputDate: Date) => {
    const selectedDate = new Date(inputDate)

    // Get the current date
    const currentDate = new Date()

    // Calculate the difference in milliseconds between the selected date and the current date
    const timeDifference = selectedDate.getTime() - currentDate.getTime()

    // Calculate the difference in days
    const daysDifference = timeDifference / (1000 * 3600 * 24)

    // Check if the difference is less than 7 days
    return Math.abs(daysDifference) < 7
  }

  const expiredText =
    refreshRequested && isDateWithin7Days(refreshRequested)
      ? 'Refresh requested'
      : 'Refresh valuation'

  const onClose = () => {
    setIsOpen(false)
  }

  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'progress-bar',
    scheduleUrl: context.calendlyLink || '',
  })

  const leadSlackRequest: {
    [key: string]: {
      slackText: string
      onRequest: (requested: boolean) => void
      setRequested: any
    }
  } = {
    [LeadStatus.SIGN_CONTRIBUTION_AGREEMENT]: {
      slackText: `Lead has requested a contribution agreement.`,
      onRequest: updateContributionAgreementRequested,
      setRequested: setCaRequested,
    },
    [LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED]: {
      slackText: `Lead has requested an inspection from the offer page.`,
      onRequest: updateScheduleInspectionRequested,
      setRequested: setInspectionRequested,
    },
  }

  const onLeadRequest = () => {
    const { slackThreadUrl } = context.user!

    if (slackThreadUrl) {
      const { channel, threadTimestamp } = decomposeSlackUrl(slackThreadUrl)
      try {
        setIsOpen(true)
        // Posts lead request on Slack thread
        postSlackMessage({
          variables: {
            postSlackMessageInput: {
              channel,
              threadTimestamp,
              text: leadSlackRequest[currentStatus].slackText,
            },
          },
        })

        // Update lead answers accordingly
        leadSlackRequest[currentStatus].onRequest(true)
        leadSlackRequest[currentStatus].setRequested(true)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const ctaTextVar =
    leadUuid === 'cbb65871-80b5-4806-b8b6-9300ccf366d0'
      ? 'Request Contribution Agreement'
      : 'Schedule a call'
  const descriptionVar =
    leadUuid === 'cbb65871-80b5-4806-b8b6-9300ccf366d0'
      ? 'Ready to take the next step? Request your contribution agreement by emailing Ari.'
      : 'Schedule a call to discuss your final valuation with your advisor.'
  const onClickFunc =
    leadUuid === 'cbb65871-80b5-4806-b8b6-9300ccf366d0'
      ? () => {
          window.open(
            `mailto:ari@flockhomes.com?subject=Request%20Contribution%20Agreement`
          )
        }
      : onOpenCalendly
  const displayedDateTime = getTimeForZip(inspectionDate, zipcode)
  let scheduleAnInspectionBookedText = displayedDateTime
    ? `Your inspection is scheduled for ${displayedDateTime}. We'll prepare a final valuation after the inspection is complete.`
    : `Your inspection has been scheduled. We'll prepare a final valuation after the inspection is complete.`
  if (transactionType === Core_TransactionType.TransactionTypePortfolio_721) {
    scheduleAnInspectionBookedText =
      'Your portfolio inspections are now underway. Please reach out to your advisor with any questions.'
  }

  let scheduleAnInspectionPostInspectionText = `We are reviewing your inspection results now.`
  if (transactionType === Core_TransactionType.TransactionTypePortfolio_721) {
    scheduleAnInspectionPostInspectionText =
      'We are reviewing the inspection results now. Please reach out to your advisor with any questions.'
  }

  const leadStatusDetails: {
    [key: string]: {
      ctaText: string
      description: string
      disabled?: boolean
      onClick?: () => void
    }
  } = {
    [LeadStatus.GET_AN_OFFER]: {
      ctaText: 'Schedule a call',
      description: isExpired
        ? `Schedule a call with us and we'll refresh this offer for you.`
        : `We will have an initial valuation ready for you within 24 hours. Learn more about how Flock works by scheduling a call with one of our advisors.`,
      onClick: onOpenCalendly,
    },
    [LeadStatus.DISCUSS_YOUR_OFFER]: {
      ctaText: 'Schedule a call',
      description:
        'Schedule a call to discuss your initial valuation with your advisor.',
      onClick: onOpenCalendly,
    },
    [LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED]: {
      ctaText: inspectionRequested
        ? 'Inspection requested'
        : 'Schedule an inspection',
      description:
        transactionType === Core_TransactionType.TransactionTypePortfolio_721
          ? `Request to schedule a portfolio inspection with your advisor.`
          : `Request to schedule an inspection with your advisor.`,
      onClick: onLeadRequest,
    },
    [LeadStatus.SCHEDULE_AN_INSPECTION_BOOKED]: {
      ctaText: 'Pending inspection results',
      description: scheduleAnInspectionBookedText,
    },
    [LeadStatus.SCHEDULE_AN_INSPECTION_POST_INSPECTION]: {
      ctaText:
        transactionType === Core_TransactionType.TransactionTypePortfolio_721
          ? `Reviewing inspection results`
          : `We're working on your final valuation`,
      description: scheduleAnInspectionPostInspectionText,
    },
    [LeadStatus.REVIEW_FINAL_OFFER]: {
      ctaText: ctaTextVar,
      description: descriptionVar,
      onClick: onClickFunc,
    },
    [LeadStatus.SIGN_CONTRIBUTION_AGREEMENT]: {
      ctaText: caRequested
        ? 'Contribution agreement requested'
        : 'Request contribution agreement',
      description:
        'Ready to take the next step? Please request the contribution agreement from your advisor.',
      onClick: onLeadRequest,
    },
    [LeadStatus.CLOSING_PROCESSES]: {
      ctaText: 'Sign in',
      description:
        'Sign in to your investor portal to continue the exchange process.',
      onClick: () => {
        window.location.href = INVESTOR_URL
      },
    },
  }

  const onExpiredClick = () => {
    const { slackThreadUrl } = context.user!

    if (slackThreadUrl) {
      const { channel, threadTimestamp } = decomposeSlackUrl(slackThreadUrl)
      try {
        // Posts lead request on Slack thread
        postSlackMessage({
          variables: {
            postSlackMessageInput: {
              channel,
              threadTimestamp,
              text: `Lead has requested an offer refresh. cc: <@${context.slackId}>`,
            },
          },
        })

        // Update lead answers accordingly
        const currentTime = new Date()
        updateRefreshOfferRequested(currentTime)
        setRefreshRequested(currentTime)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const { ctaText, description, onClick } = leadStatusDetails[currentStatus]
  const scheduleInspectionStatuses =
    transactionType === Core_TransactionType.TransactionTypePortfolio_721
      ? [
          LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED,
          LeadStatus.SCHEDULE_AN_INSPECTION_BOOKED,
        ]
      : [
          LeadStatus.SCHEDULE_AN_INSPECTION_PRE_INSPECTION_BOOKED,
          LeadStatus.SCHEDULE_AN_INSPECTION_BOOKED,
          LeadStatus.SCHEDULE_AN_INSPECTION_POST_INSPECTION,
        ]

  const reviewFinalOfferStatuses =
    transactionType === Core_TransactionType.TransactionTypePortfolio_721
      ? [
          LeadStatus.SCHEDULE_AN_INSPECTION_POST_INSPECTION,
          LeadStatus.REVIEW_FINAL_OFFER,
        ]
      : [LeadStatus.REVIEW_FINAL_OFFER]

  if (isMobile) {
    return (
      <>
        <ScheduleCall {...otherCalendlyProps} user={context.user} />
        <Modal open={isOpen} onClose={onClose}>
          <ModalWrapper>
            <Box position="fixed" top="16px" right="16px">
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" gap="16px">
              <Typography variant="h3" color="moneyGreen.main">
                Thanks for requesting{' '}
                {currentStatus === LeadStatus.SIGN_CONTRIBUTION_AGREEMENT
                  ? `your contribution agreement`
                  : `to schedule your inspection`}
                !
              </Typography>
              <Typography variant="p1" color="gray8.main">
                Your advisor will be in touch with you shortly.
              </Typography>
            </Box>
          </ModalWrapper>
        </Modal>
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          justifyContent="center"
          textAlign="center"
        >
          <Typography
            variant="cta2"
            color={isExpired ? 'errorRed.main' : 'gray8.main'}
            sx={{
              [theme.breakpoints.down('sm')]: {
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
              },
            }}
          >
            {isExpired ? 'Your valuation has expired' : 'Next step'}
          </Typography>
          <TrackedButton
            size="small"
            onClick={isExpired ? onExpiredClick : onClick}
            disabled={
              !onClick ||
              (refreshRequested && isDateWithin7Days(refreshRequested))
            }
          >
            <Typography variant="cta2">
              {isExpired ? expiredText : ctaText}
            </Typography>
          </TrackedButton>
          <Typography variant="p3" sx={{ textAlign: 'left' }}>
            {isExpired
              ? `Schedule a call to discuss refreshing your valuation with your advisor.`
              : description}
          </Typography>
        </Box>
      </>
    )
  } else {
    return (
      <>
        <ScheduleCall {...otherCalendlyProps} user={context.user} />
        <Modal open={isOpen} onClose={onClose}>
          <ModalWrapper>
            <Box position="fixed" top="16px" right="16px">
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
            <Box display="flex" flexDirection="column" gap="16px">
              <Typography variant="h3" color="moneyGreen.main">
                Thanks for requesting{' '}
                {currentStatus === LeadStatus.SIGN_CONTRIBUTION_AGREEMENT
                  ? `your contribution agreement`
                  : `to schedule your inspection`}
                !{' '}
              </Typography>
              <Typography variant="p1" color="gray8.main">
                Your advisor will be in touch with you shortly.
              </Typography>
            </Box>
          </ModalWrapper>
        </Modal>
        <Box display="flex" flexDirection="column" gap="32px">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {isExpired ? (
              <Typography variant="cta2" color="errorRed.main">
                Your valuation has expired.
              </Typography>
            ) : (
              <Typography variant="cta2">Next step</Typography>
            )}
            <TrackedButton
              size="small"
              onClick={isExpired ? onExpiredClick : onClick}
              disabled={
                !onClick ||
                (refreshRequested && isDateWithin7Days(refreshRequested))
              }
            >
              <Typography variant="cta2">
                {isExpired ? expiredText : ctaText}
              </Typography>
            </TrackedButton>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <ProgressIndicatorWithExpiration
              label="Receive initial valuation"
              statuses={[LeadStatus.GET_AN_OFFER]}
              currentStatus={currentStatus}
            />
            <ProgressIndicatorWithExpiration
              label="Discuss initial valuation"
              statuses={[LeadStatus.DISCUSS_YOUR_OFFER]}
              currentStatus={currentStatus}
            />
            <ProgressIndicatorWithExpiration
              label="Schedule inspection"
              statuses={scheduleInspectionStatuses}
              currentStatus={currentStatus}
            />
            <ProgressIndicatorWithExpiration
              label={
                leadUuid === 'cbb65871-80b5-4806-b8b6-9300ccf366d0'
                  ? 'Request Contribution Agreement'
                  : 'Review final valuation'
              }
              statuses={reviewFinalOfferStatuses}
              currentStatus={currentStatus}
            />
            <ProgressIndicatorWithExpiration
              label="Sign and close"
              statuses={[
                LeadStatus.SIGN_CONTRIBUTION_AGREEMENT,
                LeadStatus.CLOSING_PROCESSES,
              ]}
              currentStatus={currentStatus}
            />
          </Box>
          <Typography variant="p3">{description}</Typography>
        </Box>
      </>
    )
  }
}

export default ProgressBarDisplay
