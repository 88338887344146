import React from 'react'
import OfferTasksDesktop from './OfferTasksDesktop'
import OfferTasksTablet from './OfferTasksTablet'
import OfferTasksMobile from './OfferTasksMobile'
import useOfferTasks from './useOfferTasks'
import { OfferTasksProps } from './offerTasksTypes'

const OfferTasks = (props: OfferTasksProps) => {
  const presentationalProps = useOfferTasks(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <OfferTasksMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <OfferTasksTablet {...presentationalProps} />
  }
  return <OfferTasksDesktop {...presentationalProps} />
}

export default OfferTasks
