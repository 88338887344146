import _ from 'lodash'
import {
  DEFAULT_DISTRIBUTION_FACTOR,
  DEFAULT_FUND_EQUITY_PERCENT_TARGET,
} from '../businessConstants'

// TODO this file is only used for a couple constants I believe we should cleanup and remove this file

const targetYield = DEFAULT_DISTRIBUTION_FACTOR

type YearlyPerformance = {
  initialEquity: number
  appreciation: number
  managementFee: number
  distribution: number
}

const projectFullYearPerformance = (
  equity: number,
  annualHPA: number,
  targetDistribution: number
): YearlyPerformance => {
  const hpaGrowthRate = 1 + annualHPA * (1 / DEFAULT_FUND_EQUITY_PERCENT_TARGET)
  const appreciatedEquity = equity * hpaGrowthRate

  const flockManagementFeeRate = 0.01
  const flockManagementFee = appreciatedEquity * flockManagementFeeRate

  const appreciation = appreciatedEquity - equity

  return {
    initialEquity: equity,
    appreciation,
    managementFee: flockManagementFee,
    distribution: targetDistribution,
  }
}

const projectFirstYearPerformance = (
  equity: number,
  annualHPA: number,
  targetDistribution: number,
  months: number = 12
): YearlyPerformance => {
  const proratedHpaGrowthRate =
    1 + (months / 12) * annualHPA * (1 / DEFAULT_FUND_EQUITY_PERCENT_TARGET)
  const appreciatedEquity = equity * proratedHpaGrowthRate

  const flockManagementFeeRate = 0.01
  const proratedFlockManagementFee =
    appreciatedEquity * (months / 12) * flockManagementFeeRate

  const appreciation = appreciatedEquity - equity

  const proratedTargetDistribution = targetDistribution * (months / 12)

  return {
    initialEquity: equity,
    appreciation,
    managementFee: proratedFlockManagementFee,
    distribution: proratedTargetDistribution,
  }
}

type MultiYearPerformance = {
  year: number
  initialEquity: number
  appreciation: number
  managementFee: number
  distribution: number
}[]

// eslint-disable-next-line import/prefer-default-export
export const projectMultiYearPerformance = (
  initialContribution: number,
  contributionDate: Date,
  annualHPA: number,
  annualRentGrowth: number,
  yearsToProject: number = 5
) => {
  const year = contributionDate.getUTCFullYear()
  const monthsLeftInFirstYear = 11 - contributionDate.getMonth()

  let targetDistribution: number = 0
  const projections: MultiYearPerformance = []
  let yearlyPerformance

  for (let y = year; y < year + yearsToProject; y += 1) {
    if (y === year) {
      targetDistribution =
        initialContribution *
        (1 + (monthsLeftInFirstYear / 12) * annualHPA * 2) *
        (1 - 0.01 * (monthsLeftInFirstYear / 12)) *
        targetYield
      yearlyPerformance = projectFirstYearPerformance(
        initialContribution,
        annualHPA,
        targetDistribution,
        monthsLeftInFirstYear
      )
    } else {
      const lastYearsPerformance = projections[projections.length - 1]
      const lastYearsEquity =
        lastYearsPerformance.initialEquity +
        lastYearsPerformance.appreciation -
        lastYearsPerformance.managementFee

      targetDistribution =
        annualRentGrowth === 0
          ? lastYearsEquity * targetYield
          : targetDistribution * (1 + annualRentGrowth)

      yearlyPerformance = projectFullYearPerformance(
        lastYearsEquity,
        annualHPA,
        targetDistribution
      )
    }

    projections.push({ year: y, ...yearlyPerformance })
  }

  return projections
}

export type CaseTypes = 'high' | 'avg' | 'low'

export type CaseConfiguration = {
  name: string
  annualHPA: number
  annualRentGrowth: number
}

export const defaultCaseConfigurations: { [key: string]: CaseConfiguration } = {
  high: {
    name: 'high',
    annualHPA: 0.05,
    annualRentGrowth: 0.03,
  },
  avg: {
    name: 'avg',
    annualHPA: 0.03,
    annualRentGrowth: 0.02,
  },
  low: {
    name: 'low',
    annualHPA: 0,
    annualRentGrowth: 0,
  },
}

export const AccountProjectionCaseConfigurations: CaseConfiguration[] =
  Object.values(defaultCaseConfigurations).map((config) => config)

const twoMonthsFromToday = () => {
  const today = new Date()
  return new Date(today.setMonth(today.getMonth() + 2))
}

export type MultiCaseMultiYearPerformance = {
  [key: string]: MultiYearPerformance
}

export const projectMultiCaseMultiYearPerformance = (
  initialContribution: number,
  caseConfigurations: CaseConfiguration[] = AccountProjectionCaseConfigurations,
  contributionDate: Date = twoMonthsFromToday()
): MultiCaseMultiYearPerformance => {
  const projectionsByCase = _.reduce(
    caseConfigurations,
    (projectionsByCaseAcc: any, caseConfig: any) => {
      const projection = projectMultiYearPerformance(
        initialContribution,
        contributionDate,
        caseConfig.annualHPA,
        caseConfig.annualRentGrowth
      )
      return { [caseConfig.name]: projection, ...projectionsByCaseAcc }
    },
    {}
  )

  return projectionsByCase
}
