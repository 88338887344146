import React from 'react'
import AppreciationPotentialDesktop from './AppreciationPotentialDesktop'
import AppreciationPotentialTablet from './AppreciationPotentialTablet'
import AppreciationPotentialMobile from './AppreciationPotentialMobile'
import useAppreciationPotential from './useAppreciationPotential'
import { AppreciationPotentialProps } from './appreciationPotentialTypes'

const AppreciationPotential = (props: AppreciationPotentialProps) => {
  const presentationalProps = useAppreciationPotential(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <AppreciationPotentialMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <AppreciationPotentialTablet {...presentationalProps} />
  }
  return <AppreciationPotentialDesktop {...presentationalProps} />
}

export default AppreciationPotential
