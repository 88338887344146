import { generateGoogleMapUrl, GOOGLE_MAPS_API_KEY } from '@flock/shared-ui'
import { useMediaQuery, useTheme } from '@mui/material'
import { graphql, useStaticQuery } from 'gatsby'
import millify from 'millify'
import { useEffect, useMemo, useState } from 'react'
import {
  OfferPortfolioProps,
  OfferPortfolioPresentationalProps,
} from './offerPortfolioTypes'

const useOfferPortfolio: (
  props: OfferPortfolioProps
) => OfferPortfolioPresentationalProps = (props: OfferPortfolioProps) => {
  const { street, cityStateZip, condition } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  const { getPropertySummaryDetails, getDailyMetricsV1 } =
    useStaticQuery(graphql`
      query {
        getPropertySummaryDetails {
          propertyDetails {
            address {
              city
              formattedStreet
              latitude
              longitude
              state
              unit
              zipcode
            }
            baths
            beds
            halfBaths
            propertyType
            sqft
            propertyUuid
            yearBuilt
          }
        }
        getDailyMetricsV1 {
          dailyMetrics {
            state
            numProperties
            numOccupiedProperties
            totalSqft
            totalValuation
            totalOccupiedValuation
            totalMonthlyRentCents
            numPropertiesThisQuarter
          }
        }
      }
    `)

  const { propertyDetails } = getPropertySummaryDetails
  const processedAssets = useMemo(
    () =>
      propertyDetails.map((asset: any) => ({
        ...asset,
        ...asset.address,
        baths: asset.baths + (asset.halfBaths / 2 || 0),
      })),
    [propertyDetails]
  )
  const { dailyMetrics } = getDailyMetricsV1
  // defaults to portfolio, which will always be the last item in the array
  const dailyMetric = dailyMetrics[dailyMetrics.length - 1]

  const { totalValuation, numProperties } = dailyMetric
  const totalValuationNum = parseFloat(totalValuation) / 100

  const marketValueString = isMobile
    ? millify(totalValuationNum!, {
        precision: totalValuationNum > 100000000 ? 0 : 1,
        lowercase: true,
        units: ['', 'k', 'm', 'b', 't'],
      })
    : millify(totalValuationNum!, {
        precision: totalValuationNum > 100000000 ? 0 : 1,
        lowercase: true,
        units: ['', 'k', ' million', ' billion', ' trillion'],
      })

  const desktopConfig = {
    containerStyle: {
      height: '272px',
      borderRadius: '8px',
    },
    center: [-94.9903, 37.7392],
    startingZoom: 2.5,
  }
  const mobileConfig = {
    containerStyle: {
      height: '200px',
      borderRadius: '8px',
    },
    center: [-94.9903, 37.7392],
    startingZoom: 0,
  }

  const [streetViewUrl, setStreetViewUrl] = useState<string>()

  useEffect(() => {
    const streetviewMetadataUrl = generateGoogleMapUrl({
      type: 'streetview/metadata',
      location: `${street} ${cityStateZip}`,
      key: GOOGLE_MAPS_API_KEY,
    })

    fetch(streetviewMetadataUrl)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        if (resultData.status === 'OK') {
          setStreetViewUrl(
            generateGoogleMapUrl({
              type: 'streetview',
              location: `${street} ${cityStateZip}`,
              size: isMobile ? '136x136' : '248x248',
              key: GOOGLE_MAPS_API_KEY,
              fov: 80,
            })
          )
        }
      })
  }, [street, cityStateZip, isMobile])

  let homeCondition
  switch (condition) {
    case '5 - pristine':
      homeCondition = 'Near perfect'
      break
    case '4 - great':
      homeCondition = 'Normal wear and tear'
      break
    case '3 - average':
      homeCondition = 'Needs minor repairs'
      break
    case '2 - below average':
      homeCondition = 'Needs major work'
      break
    case '1 - requires complete renovation':
      homeCondition = 'Not habitable'
      break
    default:
      homeCondition = ''
      break
  }

  return {
    ...props,
    isMobile,
    isTablet,

    homeCondition,
    streetViewUrl,

    currentHomeCount: numProperties,
    marketValueString,
    processedAssets,

    mobileConfig,
    desktopConfig,
  }
}

export default useOfferPortfolio
