import { RightArrowIcon, TrackedLink } from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import ScheduleCall from '../../SharedComponents/ScheduleCall'
import OfferPagePaper from '../OfferPagePaper'
import { OfferTasksPresentationalProps } from './offerTasksTypes'

const OfferTaskDesktop = (props: OfferTasksPresentationalProps) => {
  const {
    completedItems,
    todoItems,
    otherCalendlyProps,
    context,
    onClick,
    actionText,
    description,
  } = props

  return (
    <Box display="flex" flexDirection="column" gap="32px" width="100%">
      <Typography variant="cta" color="moneyGreen.main">
        See your next steps.
      </Typography>
      <OfferPagePaper>
        <Box display="flex" flexDirection="column" gap="24px">
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h4" color="gray5.main">
              Tasks you&#39;ve completed.
            </Typography>
            {completedItems.map((item) => (
              <Typography key={item} variant="p2" color="gray5.main">
                <s>{item}</s>
              </Typography>
            ))}
          </Box>
          <Box sx={{ width: '496px', borderTop: '1px solid grey' }} />

          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h4" color="moneyGreen.main">
              Current action items.
            </Typography>
            {onClick ? (
              <>
                <ScheduleCall {...otherCalendlyProps} user={context.user} />
                <TrackedLink variant="p1" color="green4.main" onClick={onClick}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="8px"
                    alignItems="center"
                  >
                    {actionText}
                    <RightArrowIcon height="24px" width="24px" color="green4" />
                  </Box>
                </TrackedLink>
              </>
            ) : (
              <>
                <Typography variant="p1" color="gray5.main">
                  {actionText}
                </Typography>
                {description && (
                  <Typography variant="p2" color="gray5.main">
                    {description}
                  </Typography>
                )}
              </>
            )}
          </Box>
          <Box sx={{ width: '496px', borderTop: '1px solid grey' }} />

          {todoItems.length > 0 && (
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography variant="h4" color="moneyGreen.main">
                Next steps.
              </Typography>
              {todoItems.map((item) => (
                <Typography key={item} variant="p2" color="moneyGreen.main">
                  {item}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </OfferPagePaper>
    </Box>
  )
}

export default OfferTaskDesktop
