import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const SfrIconIcon = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (
    <svg
      width={width || 16}
      height={height || 18}
      viewBox="0 0 16 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4062 7.4688L8 1.7196L1.59375 7.4688V16.6562H2.375V13.375C2.375 11.9943 3.49429 10.875 4.875 10.875C6.25571 10.875 7.375 11.9943 7.375 13.375V16.6562H14.4062V7.4688ZM6.28125 13.375V16.6562H3.46875V13.375C3.46875 12.5983 4.09835 11.9687 4.875 11.9687C5.65165 11.9687 6.28125 12.5983 6.28125 13.375ZM0.96875 17.75L2.84375 17.75H6.90625L15.0312 17.75C15.2901 17.75 15.5 17.5401 15.5 17.2812V7.18992C15.5 7.0568 15.4434 6.92996 15.3443 6.84105L8.31308 0.530958C8.13497 0.37111 7.86504 0.371109 7.68692 0.530958L0.655667 6.84105C0.556597 6.92996 0.5 7.0568 0.5 7.18992V17.2812C0.5 17.5401 0.709867 17.75 0.96875 17.75ZM10.9688 13.5312V11.9687H12.5312V13.5312H10.9688ZM9.875 11.3437C9.875 11.0849 10.0849 10.875 10.3438 10.875H13.1562C13.4151 10.875 13.625 11.0849 13.625 11.3437V14.1562C13.625 14.4151 13.4151 14.625 13.1562 14.625H10.3438C10.0849 14.625 9.875 14.4151 9.875 14.1562V11.3437Z"
        fill={fillStyle || '#2B2E31'}
      />
    </svg>
  )

  return (
    <SvgIcon
      component={cleanedPaths}
      inheritViewBox
      sx={styles}
      {...otherProps}
    />
  )
}

export default SfrIconIcon
