import {
  Box,
  Typography,
  Paper,
  Slider,
  styled,
  TextField,
} from '@mui/material'
import {
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ComposedChart,
} from 'recharts'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { calculateFlexibleCashFlowModel } from '@flock/utils/src/money/flexibleCashFlowCalculator'
import millify from 'millify'
import { FlexibleCashFlowPresentationalProps } from './flexibleCashflowCalculatorTypes'

const StyledSlider = styled(Slider)({
  '& .MuiSlider-track': {
    height: '2px',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '3px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
})

const StyledComposedChart = styled(ComposedChart)({
  '& .recharts-cartesian-grid > .recharts-cartesian-grid-horizontal > line:nth-last-child(2)':
    {
      display: 'none!important',
    },
})

const shortenIfGreaterThan1M = (amt: number) => {
  if (amt < 1000000) {
    return `$${Math.round(amt).toLocaleString()}`
  }

  const millfiedAmount = millify(amt!, {
    precision: 2,
    lowercase: true,
    space: false,
    units: ['', 'k', 'm', 'b', 't'],
  })

  return `$${millfiedAmount}`
}

const currentDate = new Date()
currentDate.setMonth(currentDate.getMonth() + 1)
const currentYear = currentDate.getFullYear()
const currentMonthString = currentDate
  .toLocaleString('en-US', {
    month: 'short',
  })
  .toUpperCase()

const getCashFlowRange = (netYield: number) => {
  if (netYield < 0.06) {
    return Math.round(netYield * 10000) / 100
  }
  return 6
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        sx={{
          background: 'gray1.main',
          padding: '12px',
          borderRadius: '8px',
          boxShadow: `0px 8px 32px 0px rgba(91, 115, 139, 0.24)`,
        }}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="p2" color="moneyGreen.main">
            Account Value
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap="4px"
          >
            <Typography
              variant="p3"
              sx={{ paddingLeft: '4px' }}
            >{`High (12%) : `}</Typography>
            <Typography
              variant="p3"
              sx={{ paddingLeft: '4px' }}
            >{`$${Math.round(
              payload[0].value[0]
            ).toLocaleString()}`}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap="4px"
          >
            <Box display="flex" gap="2px">
              <Box
                width="3px"
                height="14px"
                sx={{
                  background: 'moneyGreen.main',
                  color: 'black',
                  backgroundColor: 'moneyGreen.main',
                }}
              />
              <Typography
                variant="p3"
                sx={{ fontWeight: 500 }}
              >{`Average (10%) : `}</Typography>
            </Box>
            <Typography variant="p3" sx={{ fontWeight: 500 }}>{` $${Math.round(
              payload[1].value
            ).toLocaleString()}`}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            gap="4px"
          >
            <Typography
              variant="p3"
              sx={{ paddingLeft: '4px' }}
            >{`Low (8%) : `}</Typography>
            <Typography
              variant="p3"
              sx={{ paddingLeft: '4px' }}
            >{`$${Math.round(
              payload[0].value[1]
            ).toLocaleString()}`}</Typography>
          </Box>
        </Box>
      </Paper>
    )
  }

  return null
}

const FlexibleCashflowCalculatorDesktop = (
  props: FlexibleCashFlowPresentationalProps
) => {
  const {
    startingInvestment,
    hideNetYield,
    uwCashOnCashYield: netYield,
    showCashFlowDeductions: overrideShowDeductions,
    holdingPeriodOverride,
  } = props

  const tickFormatterY = useCallback((val: any) => {
    const precision = val / 100 < 100000 || val / 100 > 1000000 ? 1 : 0 // if val is between 100k and 1m, show no decimal places since we will overflow on the chart
    return `$${millify(val, {
      precision,
      lowercase: true,
      units: ['', 'K', 'M', 'B', 'T'],
    })}`
  }, [])

  const cashFlowRange = getCashFlowRange(netYield)
  const [cashFlowSlider, setCashFlowSlider] = useState(
    hideNetYield ? 0 : cashFlowRange - 2
  )
  const flexibleCashFlowOutputs = calculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield,
    cashFlowPercentage: 0.01 * cashFlowSlider,
    holdingPeriodOverride,
    hideNetYield,
  })
  const endingFirstYearBalanceForCashFlow =
    flexibleCashFlowOutputs.initialAccountValueAvg[0]

  // we use noCashFlowOutputs to calculate the upper bound of projections in 5 years so the graph ticks can stay consistent
  const noCashFlowOutputs = calculateFlexibleCashFlowModel({
    startingEquity: startingInvestment,
    netYield,
    cashFlowPercentage: 0,
    holdingPeriodOverride,
    hideNetYield,
  })
  const graphDomain = [
    noCashFlowOutputs.initialAccountValueLow[0],
    noCashFlowOutputs.initialAccountValueHigh[4],
  ]

  const data = [
    {
      name: `${currentMonthString} ${currentYear}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[0],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[0],
        flexibleCashFlowOutputs.initialAccountValueLow[0],
      ],
    },
    {
      name: `${currentMonthString} ${currentYear + 1}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[1],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[1],
        flexibleCashFlowOutputs.initialAccountValueLow[1],
      ],
    },
    {
      name: `${currentMonthString} ${currentYear + 2}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[2],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[2],
        flexibleCashFlowOutputs.initialAccountValueLow[2],
      ],
    },
    {
      name: `${currentMonthString} ${currentYear + 3}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[3],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[3],
        flexibleCashFlowOutputs.initialAccountValueLow[3],
      ],
    },
    {
      name: `${currentMonthString} ${currentYear + 4}`,
      'Total Return': flexibleCashFlowOutputs.initialAccountValueAvg[4],
      range: [
        flexibleCashFlowOutputs.initialAccountValueHigh[4],
        flexibleCashFlowOutputs.initialAccountValueLow[4],
      ],
    },
  ]

  const PotentialAnnualEarnings = (
    <>
      <Box>
        <Typography variant="p2" color="gray8.main">
          Your starting account value
        </Typography>
      </Box>
      <Box>
        <Typography
          fontSize="32px"
          lineHeight="40px"
          fontWeight="600"
          color="moneyGreen.main"
        >
          ${startingInvestment.toLocaleString()}
        </Typography>
      </Box>
      <Box>
        <Typography variant="c1" color="green3.main">
          Target annualized return of 8-12% comprised of cash flow &
          appreciation
        </Typography>
      </Box>
    </>
  )

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box display="flex" gap="8px" flexDirection="column">
        {PotentialAnnualEarnings}
      </Box>
      {!hideNetYield && (
        <Paper
          sx={{
            padding: '16px',
            backgroundColor: 'gray1.main',
            borderRadius: '8px',
          }}
        >
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              gap="16px"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="p2">I want to take</Typography>
                <Box width="160px" height="40px!important">
                  <TextField
                    name="email"
                    type="money"
                    value={`$${Math.round(
                      cashFlowSlider * endingFirstYearBalanceForCashFlow * 0.01
                    ).toLocaleString()}`}
                    variant="outlined"
                    size="small"
                    fullWidth
                    inputProps={{
                      min: 0,
                      style: { textAlign: 'center', padding: '0px!important' },
                    }}
                    sx={{
                      '& > input': {
                        padding: 0,
                      },
                      width: '160px',
                      '& > div > input': {
                        fontSize: '1rem!important',
                        padding: '0!important',
                        height: '40px!important',
                      },
                    }}
                    InputLabelProps={{
                      style: { padding: '0px!important' },
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (
                        e.target.value[0] === '$' &&
                        Number(e.target.value.replace(/,/g, '').substring(1)) <=
                          cashFlowRange *
                            endingFirstYearBalanceForCashFlow *
                            0.01
                      ) {
                        setCashFlowSlider(
                          (Math.abs(
                            Number(
                              e.target.value.replace(/,/g, '').substring(1)
                            )
                          ) *
                            100) /
                            endingFirstYearBalanceForCashFlow
                        )
                      } else if (
                        Number(e.target.value) <=
                        cashFlowRange * endingFirstYearBalanceForCashFlow * 0.01
                      ) {
                        setCashFlowSlider(
                          (Math.abs(Number(e.target.value)) * 100) /
                            endingFirstYearBalanceForCashFlow
                        )
                      } else {
                        // TODO - Add in pop-up here
                        setCashFlowSlider(cashFlowRange)
                      }
                    }}
                  />
                </Box>
                <Typography variant="p2">a year in cash flow.</Typography>
              </Box>
              <StyledSlider
                min={0}
                value={cashFlowSlider}
                defaultValue={cashFlowRange - 2}
                onChange={(_, value) =>
                  setCashFlowSlider(Array.isArray(value) ? value[0] : value)
                }
                max={cashFlowRange}
                step={0.01}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" gap="32px">
              <Typography variant="c1">$0</Typography>
              <Typography variant="c1" color="gray5.main">
                $
                {Math.round(
                  cashFlowRange * endingFirstYearBalanceForCashFlow * 0.01
                ).toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </Paper>
      )}
      <Box display="flex" flexDirection="column">
        <Box>
          <Box display="flex">
            <Box width="100%">
              <Box display="flex" gap="4px" flexDirection="column">
                <Box
                  display="flex"
                  gap="8px"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography variant="p2" color="gray8.main">
                    Projected return after one year
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    color="moneyGreen.main"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    $
                    {Math.round(
                      flexibleCashFlowOutputs.totalReturnAvg[0]
                    ).toLocaleString()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="c1" color="gray5.main">
                    10% total return
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!hideNetYield && (
              <Box width="100%">
                <Box display="flex" gap="4px" flexDirection="column">
                  <Box
                    display="flex"
                    gap="8px"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Typography
                      variant="p2"
                      color="gray8.main"
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Cash taken after one year
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      color="green4.main"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      $
                      {Math.round(
                        flexibleCashFlowOutputs.cashWithdrawnAvg[0]
                      ).toLocaleString()}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="c1" color="gray5.main">
                      {cashFlowSlider.toFixed(2)}% cash flow
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          gap="8px"
          pb="16px"
          alignItems="center"
        >
          <Typography variant="p1">
            Projected 5 Year Returns with Flock:
          </Typography>
        </Box>
        <Box>
          <ResponsiveContainer width="100%" height={200}>
            <StyledComposedChart
              width={730}
              height={250}
              data={data}
              margin={{ top: 0, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="4 1 2" />
              <XAxis dataKey="name" style={{ fontSize: '14px' }} />
              <YAxis
                width={40}
                scale="linear"
                interval="preserveStartEnd"
                domain={graphDomain}
                tickFormatter={tickFormatterY}
                tickCount={3}
                style={{ fontSize: '14px' }}
              />
              <Tooltip content={<CustomTooltip active payload label />} />
              <Legend />
              <Area
                legendType="none"
                type="monotone"
                dataKey="range"
                fill="#C9DADB"
                stroke="#C9DADB"
              />
              <Line
                legendType="none"
                type="monotone"
                dataKey="Total Return"
                stroke="#03341D"
                strokeWidth={2}
              />
            </StyledComposedChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pb="8px"
          alignItems="center"
        >
          <Box width="100px" />
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {currentMonthString} {currentYear}
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {currentMonthString} {currentYear + 1}
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {currentMonthString} {currentYear + 2}
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {currentMonthString} {currentYear + 3}
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {currentMonthString} {currentYear + 4}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pb="8px"
          alignItems="center"
        >
          <Typography variant="p3" width="100px" sx={{ whiteSpace: 'nowrap' }}>
            Initial Value
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {shortenIfGreaterThan1M(
              flexibleCashFlowOutputs.initialAccountValueAvg[0]
            )}
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {shortenIfGreaterThan1M(
              flexibleCashFlowOutputs.initialAccountValueAvg[1]
            )}
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {shortenIfGreaterThan1M(
              flexibleCashFlowOutputs.initialAccountValueAvg[2]
            )}
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {shortenIfGreaterThan1M(
              flexibleCashFlowOutputs.initialAccountValueAvg[3]
            )}
          </Typography>
          <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
            {shortenIfGreaterThan1M(
              flexibleCashFlowOutputs.initialAccountValueAvg[4]
            )}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pb="8px"
          alignItems="center"
        >
          <Typography
            variant="p3"
            color="gray8.main"
            width="100px"
            sx={{ whiteSpace: 'nowrap' }}
          >
            Total Return
          </Typography>
          <Typography
            variant="p3"
            width="80px"
            color="gray8.main"
            sx={{ textAlign: 'right' }}
          >
            {shortenIfGreaterThan1M(flexibleCashFlowOutputs.totalReturnAvg[0])}
          </Typography>
          <Typography
            variant="p3"
            width="80px"
            color="gray8.main"
            sx={{ textAlign: 'right' }}
          >
            {shortenIfGreaterThan1M(flexibleCashFlowOutputs.totalReturnAvg[1])}
          </Typography>
          <Typography
            variant="p3"
            width="80px"
            color="gray8.main"
            sx={{ textAlign: 'right' }}
          >
            {shortenIfGreaterThan1M(flexibleCashFlowOutputs.totalReturnAvg[2])}
          </Typography>
          <Typography
            variant="p3"
            width="80px"
            color="gray8.main"
            sx={{ textAlign: 'right' }}
          >
            {shortenIfGreaterThan1M(flexibleCashFlowOutputs.totalReturnAvg[3])}
          </Typography>
          <Typography
            variant="p3"
            color="gray8.main"
            width="80px"
            sx={{ textAlign: 'right' }}
          >
            {shortenIfGreaterThan1M(flexibleCashFlowOutputs.totalReturnAvg[4])}
          </Typography>
        </Box>
        {!hideNetYield && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="4px"
            alignItems="center"
          >
            <Typography
              variant="p3"
              width="100px"
              color="green4.main"
              sx={{ whiteSpace: 'nowrap' }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Cash Taken
            </Typography>
            <Typography
              variant="p3"
              width="80px"
              color="green4.main"
              sx={{ textAlign: 'right' }}
            >
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashWithdrawnAvg[0]
              )}
            </Typography>
            <Typography
              variant="p3"
              width="80px"
              color="green4.main"
              sx={{ textAlign: 'right' }}
            >
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashWithdrawnAvg[1]
              )}
            </Typography>
            <Typography
              variant="p3"
              width="80px"
              color="green4.main"
              sx={{ textAlign: 'right' }}
            >
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashWithdrawnAvg[2]
              )}
            </Typography>
            <Typography
              variant="p3"
              width="80px"
              color="green4.main"
              sx={{ textAlign: 'right' }}
            >
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashWithdrawnAvg[3]
              )}
            </Typography>
            <Typography
              variant="p3"
              color="green4.main"
              width="80px"
              sx={{ textAlign: 'right' }}
            >
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashWithdrawnAvg[4]
              )}
            </Typography>
          </Box>
        )}
        {overrideShowDeductions ? (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            pb="8px"
            alignItems="center"
          >
            <Typography
              variant="p3"
              width="100px"
              sx={{ whiteSpace: 'nowrap' }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;Deductions<sup>1</sup>
            </Typography>
            <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashDeductionAvg[0]
              )}
            </Typography>
            <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashDeductionAvg[1]
              )}
            </Typography>
            <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashDeductionAvg[2]
              )}
            </Typography>
            <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashDeductionAvg[3]
              )}
            </Typography>
            <Typography variant="p3" width="80px" sx={{ textAlign: 'right' }}>
              {shortenIfGreaterThan1M(
                flexibleCashFlowOutputs.cashDeductionAvg[4]
              )}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default FlexibleCashflowCalculatorDesktop
