import React from 'react'
import OfferPortfolioDesktop from './OfferPortfolioDesktop'
import OfferPortfolioTablet from './OfferPortfolioTablet'
import OfferPortfolioMobile from './OfferPortfolioMobile'
import useOfferPortfolio from './useOfferPortfolio'
import { OfferPortfolioProps } from './offerPortfolioTypes'

const OfferPortfolio = (props: OfferPortfolioProps) => {
  const presentationalProps = useOfferPortfolio(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <OfferPortfolioMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <OfferPortfolioTablet {...presentationalProps} />
  }
  return <OfferPortfolioDesktop {...presentationalProps} />
}

export default OfferPortfolio
