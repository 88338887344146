import { TrackedAccordion } from '@flock/shared-ui'
import { Box, Typography } from '@mui/material'
import React from 'react'
import OfferPagePaper from '../OfferPagePaper'
import { OfferQuestionsPresentationalProps } from './offerQuestionsTypes'

const OfferQuestionsDesktop = (props: OfferQuestionsPresentationalProps) => {
  const { offerQuestions } = props

  return (
    <Box display="flex" flexDirection="column" gap="32px" width="100%">
      <Typography variant="cta" color="moneyGreen.main">
        Your questions, answered.
      </Typography>
      <OfferPagePaper borderRadius="16px">
        <Box display="flex" flexDirection="column" gap="16px">
          {offerQuestions.map((offerQuestion: { title: any; text: any }) => {
            const { title, text } = offerQuestion
            return (
              <TrackedAccordion
                summary={title}
                disableGutters
                styleVariant="filled"
                collapsible={false}
                sx={{
                  '& .MuiAccordionDetails-root': {
                    padding: '0px 16px 16px 16px',
                  },
                  '& .MuiTypography-h4': {
                    fontSize: '16px!important',
                  },
                }}
              >
                <Box>
                  <Typography variant="p3" sx={{ fontSize: '14px!important' }}>
                    {text}
                  </Typography>
                </Box>
              </TrackedAccordion>
            )
          })}
        </Box>
      </OfferPagePaper>
    </Box>
  )
}

export default OfferQuestionsDesktop
