import { Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import SectionLayout from '../SharedComponents/SectionLayout'

type OfferPageSectionProps = {
  name: string
  id: string
  children: React.ReactNode
  backgroundColor: string | undefined
  shouldHide?: boolean
  verticalPadding?: string
}

const OfferPageSection = (props: OfferPageSectionProps) => {
  const { name, id, children, backgroundColor, shouldHide } = props
  let { verticalPadding } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  if (verticalPadding === '') {
    verticalPadding = isMobile ? '16px' : '24px'
  }
  let numColumns = 12
  if (isMobile) {
    numColumns = 4
  } else if (isTablet) {
    numColumns = 7
  } else {
    numColumns = 12
  }

  return (
    <>
      <SectionLayout
        name={name}
        backgroundColor={backgroundColor}
        verticalPadding={verticalPadding}
        sidePadding="0px"
        columns={numColumns}
        shouldHide={shouldHide}
      >
        {!isMobile ? <Grid item sm={1} md={3} /> : null}
        <Grid
          item
          xs={4}
          sm={6}
          id={id}
          sx={{ scrollMarginTop: isMobile ? '56px' : undefined }}
        >
          {children}
        </Grid>
        {!isTablet ? <Grid item md={3} /> : null}
      </SectionLayout>
    </>
  )
}

export default OfferPageSection

OfferPageSection.defaultProps = {
  shouldHide: false,
  verticalPadding: '',
}
