import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  BathroomIcon,
  BedroomIcon,
  HouseFilledIcon,
  HouseOutlinedIcon,
  LeftArrowFilledIcon,
  MoneyFilledIcon,
  PortfolioMapbox,
  SparkleIcon,
} from '@flock/shared-ui'
import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { MAPBOX_TOKEN } from '../../../constants'
import HomeDetail from '../HomeDetail'
import PortfolioHomeDetails from '../PortfolioHomeDetails'
import { OfferPortfolioPresentationalProps } from './offerPortfolioTypes'

const OfferPortfolioDesktop = (props: OfferPortfolioPresentationalProps) => {
  const {
    streetViewUrl,
    street,
    bedrooms,
    bathrooms,
    homeType,
    homeCondition,
    currentHomeCount,
    desktopConfig,
    marketValueString,
    processedAssets,
    transactionType,
    leadName,
    portfolioName,
    portfolioDoorCount,
    portfolioAssetCount,
    portfolioCityState,
    portfolioSFRDoors,
    portfolioMultifamilyDoors,
    capex,
    offerPrice,
  } = props

  const HomeDetailComponents = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Typography variant="cta2">{street}</Typography>
      <Box display="flex" flexDirection="row" gap="8px">
        {bedrooms ? (
          <HomeDetail icon={BedroomIcon} text={`${bedrooms} bed`} />
        ) : null}
        {bathrooms ? (
          <HomeDetail icon={BathroomIcon} text={`${bathrooms} bath`} />
        ) : null}
      </Box>
      {homeType ? (
        <HomeDetail icon={HouseOutlinedIcon} text={homeType} />
      ) : null}
      {homeCondition ? (
        <HomeDetail icon={SparkleIcon} text={homeCondition} />
      ) : null}
    </Box>
  )

  const TwoColumnHomeDetailComponents = (
    <Box
      display="flex"
      flexDirection="row"
      gap="32px"
      justifyContent="space-between"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="12px"
        height="96px"
        width="50%"
      >
        {bedrooms ? (
          <HomeDetail icon={BedroomIcon} text={`${bedrooms} bed`} />
        ) : null}
        {bathrooms ? (
          <HomeDetail icon={BathroomIcon} text={`${bathrooms} bath`} />
        ) : null}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap="12px"
        height="96px"
        width="50%"
      >
        {homeType ? (
          <HomeDetail icon={HouseOutlinedIcon} text={homeType} />
        ) : null}
        {homeCondition ? (
          <HomeDetail icon={SparkleIcon} text={homeCondition} />
        ) : null}
      </Box>
    </Box>
  )

  return (
    <>
      {transactionType === Core_TransactionType.TransactionTypePortfolio_721 ? (
        <PortfolioHomeDetails
          leadName={leadName}
          portfolioName={portfolioName}
          portfolioDoorCount={portfolioDoorCount}
          portfolioAssetCount={portfolioAssetCount}
          portfolioCityState={portfolioCityState}
          portfolioSFRDoors={portfolioSFRDoors}
          portfolioMultifamilyDoors={portfolioMultifamilyDoors}
          capex={capex}
          offerPrice={offerPrice}
        />
      ) : (
        <Box display="flex" flexDirection="row">
          {streetViewUrl ? (
            <>
              <Box
                component="img"
                src={streetViewUrl}
                alt={`Picture of ${street}`}
                width="50%"
                height="248px"
                sx={{
                  boxShadow: 8,
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                }}
              />

              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                padding="16px"
                sx={{
                  boxShadow: 8,
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  backgroundColor: 'green0.main',
                }}
              >
                <>{HomeDetailComponents}</>
              </Box>
            </>
          ) : (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              padding="24px"
              height="136px"
              sx={{
                boxShadow: 8,
                borderRadius: '8px',
                backgroundColor: 'green0.main',
              }}
            >
              <Typography
                sx={{ paddingBottom: '16px', textAlign: 'center' }}
                variant="cta2"
              >
                {street}
              </Typography>
              <>{TwoColumnHomeDetailComponents}</>
            </Box>
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        width="100%"
        pt="16px"
      >
        <Paper
          sx={{
            padding: '0px',
            backgroundColor: 'rgb(235, 241, 241, 0.5)',
            borderRadius: '32px',
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            gap="24px"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              sx={{ transform: 'rotate(-90deg)' }}
            >
              <LeftArrowFilledIcon height="48px" width="48px" />
            </Box>
            <Typography variant="c1" color="moneyGreen.main">
              {transactionType ===
              Core_TransactionType.TransactionTypePortfolio_721
                ? "Your homes enter Flock's professionally managed, diversified portfolio of rental properties."
                : "Your home enters Flock's professionally managed, diversified portfolio of rental properties."}
            </Typography>
          </Box>
        </Paper>
        <PortfolioMapbox
          propertyAssets={processedAssets}
          mapboxToken={MAPBOX_TOKEN || ''}
          desktopConfig={desktopConfig}
        />
        <Box
          display="flex"
          flexDirection="row"
          gap="16px"
          width="100%"
          justifyContent="space-between"
        >
          <Paper
            sx={{
              padding: '16px',
              backgroundColor: 'green0.main',
              borderRadius: '8px',
              width: '50%',
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="16px"
              alignItems="center"
            >
              <HouseFilledIcon width="49px" height="49px" color="moneyGreen" />
              <Box
                display="flex"
                flexDirection="row"
                gap="24px"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column" alignItems="left">
                  <Typography
                    variant="h4"
                    fontWeight={700}
                    color="moneyGreen.main"
                  >
                    {currentHomeCount}
                  </Typography>
                  <Typography variant="p2" color="moneyGreen.main">
                    homes in portfolio
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>

          <Paper
            sx={{
              padding: '16px',
              backgroundColor: 'green0.main',
              borderRadius: '8px',
              width: '50%',
            }}
          >
            <Box
              display="flex"
              flexDirection="row"
              gap="16px"
              alignItems="center"
            >
              <MoneyFilledIcon width="49px" height="49px" color="moneyGreen" />
              <Box
                display="flex"
                flexDirection="row"
                gap="12px"
                alignItems="center"
              >
                <Box display="flex" flexDirection="column" alignItems="left">
                  <Typography
                    variant="h4"
                    fontWeight={700}
                    color="moneyGreen.main"
                  >
                    ${marketValueString}
                  </Typography>
                  <Typography variant="p2" color="moneyGreen.main">
                    portfolio value
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  )
}

export default OfferPortfolioDesktop
