import { InfoIcon, TrackedLink } from '@flock/shared-ui'
import {
  Box,
  Typography,
  Paper,
  Switch,
  Button,
  Slider,
  Tooltip,
} from '@mui/material'
import React, { useContext } from 'react'
import moment from 'moment'
import ProjectionChartCard from '../ProjectionChartCard'
import { AppreciationPotentialPresentationalProps } from './appreciationPotentialTypes'
import { useCalendly } from '../../utils'
import ScheduleCall from '../../SharedComponents/ScheduleCall'
import OfferPageContext from '../OfferPageContext'

const AppreciationPotentialTablet = (
  props: AppreciationPotentialPresentationalProps
) => {
  const {
    leadUuid,
    firstYearIncome,
    firstYearAppreciation,
    startingInvestment,
    isReinvestmentSelected,
    toggleIsReinvestmentSelected,
    timeHorizonSlider,
    setTimeHorizonSlider,

    handleHighOnClick,
    handleAvgOnClick,
    handleLowOnClick,

    annualAppreciationPercent,
    projectionCase,

    isHighSelected,
    isAvgSelected,
    isLowSelected,

    initialValuation,
    isPreliminary,
    mortgageAmount,
    hideValuation,
    onboardingFee,
    lowerValuationNumber,
    upperValuationNumber,
    equityOverride,
    cashTakeout,
    rentReduction,
    daysInRemodelDeduction,
    shouldOverrideClosingCosts,
    closingCostsOverride,
    capexCosts,
    incomeTooltip,
    appreciationTooltip,
    reinvestDistributionsTooltip,
  } = props

  const PotentialAnnualEarnings = (
    <>
      <Box>
        <Typography variant="p2" color="gray8.main">
          Your estimated starting investment in Flock&apos;s Fund
        </Typography>
      </Box>
      <Box>
        <Typography
          fontSize="32px"
          lineHeight="40px"
          fontWeight="600"
          color="moneyGreen.main"
        >
          ${startingInvestment.toLocaleString()}
        </Typography>
      </Box>
    </>
  )

  const context = useContext(OfferPageContext)
  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'offer-page',
    scheduleUrl: context.calendlyLink as string,
  })

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Box display="flex" gap="8px" flexDirection="column">
        {PotentialAnnualEarnings}
      </Box>
      <Paper
        sx={{
          padding: '16px',
          backgroundColor: 'green0.main',
          borderRadius: '8px',
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap="8px"
          pb="16px"
          alignItems="center"
        >
          <Typography variant="p4">
            DURING YOUR FIRST YEAR AS A CLIENT, YOUR PROJECTED RETURN:
          </Typography>
        </Box>
        <Box>
          <Box display="flex" justifyContent="space-between" gap="32px">
            <Box width="100%">
              <Box display="flex" gap="8px" flexDirection="column">
                <Box
                  display="flex"
                  gap="8px"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography variant="p2" color="gray8.main">
                    Income
                  </Typography>
                  <Tooltip
                    title={incomeTooltip}
                    arrow
                    placement="right"
                    enterTouchDelay={0}
                  >
                    <Box
                      sx={{
                        display: 'inline',
                      }}
                    >
                      <InfoIcon fill="#CBCDCF" width="12px" height="12px" />
                    </Box>
                  </Tooltip>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    color="gray8.main"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    ${Math.round(firstYearIncome).toLocaleString()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="c1" color="green3.main">
                    {leadUuid === 'ac43df1d-7240-457e-8caa-8c176236bc35'
                      ? '4% annualized cash flow'
                      : '3% annualized distribution'}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box width="100%">
              <Box display="flex" gap="8px" flexDirection="column">
                <Box
                  display="flex"
                  gap="8px"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography variant="p2" color="gray8.main">
                    Appreciation
                  </Typography>
                  <Tooltip
                    title={appreciationTooltip}
                    arrow
                    placement="right"
                    enterTouchDelay={0}
                  >
                    <Box
                      sx={{
                        display: 'inline',
                      }}
                    >
                      <InfoIcon fill="#CBCDCF" width="12px" height="12px" />
                    </Box>
                  </Tooltip>
                </Box>
                <Box>
                  <Typography
                    variant="h4"
                    color="gray8.main"
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    ${Math.round(firstYearAppreciation).toLocaleString()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="c1" color="green3.main">
                    {annualAppreciationPercent}% annual appreciation
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Paper
        sx={{
          padding: '16px',
          backgroundColor: 'gray1.main',
          borderRadius: '8px',
        }}
      >
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            gap="32px"
            pb="16px"
          >
            <Box width="100%">
              <Box display="flex" gap="8px" flexDirection="column">
                <Box
                  display="flex"
                  gap="8px"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Typography variant="p2" color="gray8.main">
                    Reinvest cash flow?
                  </Typography>
                  <Tooltip
                    title={reinvestDistributionsTooltip}
                    arrow
                    placement="right"
                    enterTouchDelay={0}
                  >
                    <Box
                      sx={{
                        display: 'inline',
                      }}
                    >
                      <InfoIcon fill="#CBCDCF" width="12px" height="12px" />
                    </Box>
                  </Tooltip>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="8px"
                  alignItems="center"
                >
                  <Typography variant="c1">NO</Typography>
                  <Switch
                    checked={isReinvestmentSelected}
                    onChange={toggleIsReinvestmentSelected}
                  />
                  <Typography variant="c1">YES</Typography>
                </Box>
              </Box>
            </Box>
            <Box width="100%">
              <Box display="flex" gap="8px" flexDirection="column">
                <Box display="flex" gap="8px" flexDirection="column">
                  <Typography variant="p2" color="gray8.main">
                    Real estate market outlook
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" gap="8px">
                  <Button
                    variant={isLowSelected ? 'primary' : 'secondary'}
                    sx={{
                      width: '62px',
                      height: '16px',
                      padding: '10px 16px',
                    }}
                    onClick={handleLowOnClick}
                  >
                    <Typography variant="c1">LOW</Typography>
                  </Button>
                  <Button
                    variant={isAvgSelected ? 'primary' : 'secondary'}
                    sx={{
                      width: '62px',
                      height: '16px',
                      padding: '10px 16px',
                    }}
                    onClick={handleAvgOnClick}
                  >
                    <Typography variant="c1">AVG</Typography>
                  </Button>
                  <Button
                    variant={isHighSelected ? 'primary' : 'secondary'}
                    sx={{
                      width: '62px',
                      height: '16px',
                      padding: '10px 16px',
                    }}
                    onClick={handleHighOnClick}
                  >
                    <Typography variant="c1">HIGH</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="p2" color="gray8.main">
            Time horizon
          </Typography>
          <Typography variant="p2" color="gray8.main">
            {timeHorizonSlider}yr
          </Typography>
        </Box>
        <Box pb="16px">
          <Slider
            min={1}
            defaultValue={5}
            value={timeHorizonSlider}
            onChange={(_, value) =>
              setTimeHorizonSlider(Array.isArray(value) ? value[0] : value)
            }
            max={10}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          gap="8px"
          pb="16px"
          alignItems="center"
        >
          <Typography variant="p4">
            IN YOUR{' '}
            {moment()
              .localeData()
              .ordinal(timeHorizonSlider)
              .toLocaleUpperCase()}{' '}
            YEAR, YOUR PROJECTED RETURN:{' '}
          </Typography>
        </Box>
        <Box>
          <ProjectionChartCard
            leadUuid={leadUuid}
            initialValuation={initialValuation || 500000}
            onboardingFee={onboardingFee}
            isPreliminary={isPreliminary}
            rentReduction={rentReduction || 0}
            daysInRemodelDeduction={daysInRemodelDeduction || 0}
            lowerValuationNumber={lowerValuationNumber}
            upperValuationNumber={upperValuationNumber}
            mortgageAmount={mortgageAmount || 0}
            equityOverride={equityOverride || 0}
            shouldOverrideClosingCosts={shouldOverrideClosingCosts || false}
            closingCostsOverride={closingCostsOverride || 0}
            cashTakeout={cashTakeout || 0}
            capexCosts={capexCosts || 0}
            yearsToProject={11}
            hideValuation={hideValuation}
            reinvestDistributions={isReinvestmentSelected}
            projectionsCase={projectionCase}
            appreciationYear={timeHorizonSlider - 1}
            annualAppreciationPercent={annualAppreciationPercent}
          />
        </Box>
      </Paper>
      <Box display="flex" flexDirection="row">
        <Typography variant="p4" color="gray6.main">
          Flock&apos;s cash flow is tax-advantaged -
          <ScheduleCall {...otherCalendlyProps} />
          <TrackedLink
            variant="p4"
            color="green4.main"
            onClick={onOpenCalendly}
          >
            {' '}
            call your real estate advisor to learn more.
          </TrackedLink>
        </Typography>
      </Box>
    </Box>
  )
}

export default AppreciationPotentialTablet
