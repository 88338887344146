import React from 'react'
import OfferExchangeDesktop from './OfferExchangeDesktop'
import OfferExchangeTablet from './OfferExchangeTablet'
import OfferExchangeMobile from './OfferExchangeMobile'
import useOfferExchange from './useOfferExchange'
import { OfferExchangeProps } from './offerExchangeTypes'

const OfferExchange = (props: OfferExchangeProps) => {
  const presentationalProps = useOfferExchange(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <OfferExchangeMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <OfferExchangeTablet {...presentationalProps} />
  }
  return <OfferExchangeDesktop {...presentationalProps} />
}

export default OfferExchange
