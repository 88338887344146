import { InfoIcon } from '@flock/shared-ui'
import {
  CaseTypes,
  CompoundingGrowth,
  defaultCaseConfigurations,
  DEFAULT_CLOSING_AND_LEGAL_FEE,
  DEFAULT_DISTRIBUTION_FACTOR,
  DEFAULT_FUND_EQUITY_PERCENT_TARGET,
  projectMultiCaseFinancialModel,
} from '@flock/utils'
import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Tooltip,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

type ProjectionChartCardProps = {
  leadUuid: string
  initialValuation: number
  onboardingFee: number
  isPreliminary: boolean
  lowerValuationNumber: number
  upperValuationNumber: number
  mortgageAmount: number
  equityOverride: number
  rentReduction: number
  daysInRemodelDeduction: number
  cashTakeout: number
  shouldOverrideClosingCosts: boolean
  closingCostsOverride: number
  capexCosts: number
  yearsToProject: number
  hideValuation: boolean
  reinvestDistributions: boolean
  projectionsCase: string
  appreciationYear: number
  annualAppreciationPercent: number
}

const ProjectionChartCard = (props: ProjectionChartCardProps) => {
  const {
    leadUuid,
    initialValuation,
    onboardingFee,
    isPreliminary,
    lowerValuationNumber,
    upperValuationNumber,
    mortgageAmount: originalMortgageAmount,
    equityOverride,
    cashTakeout,
    rentReduction,
    daysInRemodelDeduction,
    shouldOverrideClosingCosts,
    closingCostsOverride,
    capexCosts,
    yearsToProject,
    reinvestDistributions,
    appreciationYear,
    hideValuation,
    projectionsCase,
    annualAppreciationPercent,
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // If we're hiding the valuation, this projection chart should show
  // a default of $500,000 without a mortgage.
  const [mortgageAmount, setMortgageAmount] = useState<number>(
    hideValuation ? 0 : originalMortgageAmount || 0
  )
  const [mortgage, setMortgage] = useState<number | null>(
    mortgageAmount || null
  )

  useEffect(() => {
    if (originalMortgageAmount) {
      setMortgageAmount(originalMortgageAmount)
      setMortgage(originalMortgageAmount)
    }
  }, [originalMortgageAmount])

  let valuation = initialValuation
  if (isPreliminary) {
    valuation = Math.round((lowerValuationNumber + upperValuationNumber) / 2)
  }
  if (hideValuation) {
    valuation = 500000
  }

  const feeModifier = 1 - onboardingFee

  const originalEquityAmount =
    Math.round(valuation * feeModifier) - (mortgageAmount || 0)

  const takeoutAmount =
    cashTakeout || (equityOverride ? originalEquityAmount - equityOverride : 0)

  const caseConfigurations = [
    defaultCaseConfigurations[projectionsCase as CaseTypes],
  ]

  // We use a different equity here because we're using the mortgage from the scenario analysis
  // rather than the mortgage that was previously set
  let closingCosts = DEFAULT_CLOSING_AND_LEGAL_FEE
  if (shouldOverrideClosingCosts) {
    closingCosts = closingCostsOverride
  }

  const projectedPerformanceByCase = projectMultiCaseFinancialModel({
    propertyValue: valuation,
    equityTakeout: takeoutAmount,
    rentReduction,
    daysInRemodelDeduction,
    onboardingFee,
    closingAndLegalFee: closingCosts,
    capexCosts,
    remainingMortgageAmount: mortgage || 0,
    flockDistributionRate:
      leadUuid === 'ac43df1d-7240-457e-8caa-8c176236bc35'
        ? 0.04
        : DEFAULT_DISTRIBUTION_FACTOR,
    reinvestDistributions,
    caseConfigurations,
    yearsToProject,
    targetContributionDate: new Date('1/1/2024'),
    fundEquityPercentTarget: DEFAULT_FUND_EQUITY_PERCENT_TARGET,
    hpaCompounding: CompoundingGrowth.QUARTERLY,
  })

  const incomeTooltip = `Model assumes the Fund meets its annual distribution target.`
  const appreciationTooltip = `Flock equity changes in value due to portfolio net income and asset valuations. Model assumes equity in Flock appreciates commensurately with the broader real estate market outlook. This scenario analysis assumes a ${
    100 * (1 - DEFAULT_FUND_EQUITY_PERCENT_TARGET)
  }% leverage ratio.`

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      gap={isMobile ? '16px' : '32px'}
    >
      <Box width="100%">
        <Box
          display="flex"
          gap={isMobile ? '4px' : '8px'}
          flexDirection="column"
        >
          <Box display="flex" gap="8px" flexDirection="row" alignItems="center">
            <Typography variant="p2" color="gray8.main">
              {reinvestDistributions && '(Reinvested) '}Income
            </Typography>
            <Tooltip
              title={incomeTooltip}
              arrow
              placement={isMobile ? 'bottom' : 'right'}
              enterTouchDelay={0}
            >
              <Box
                sx={{
                  display: 'inline',
                }}
              >
                <InfoIcon fill="#CBCDCF" width="12px" height="12px" />
              </Box>
            </Tooltip>
          </Box>
          <Box>
            <Typography
              variant="h4"
              color="gray8.main"
              sx={{
                fontWeight: 700,
              }}
            >
              $
              {reinvestDistributions
                ? Math.round(
                    projectedPerformanceByCase[projectionsCase][
                      appreciationYear + 1
                    ].initialEquity -
                      projectedPerformanceByCase[projectionsCase][
                        appreciationYear
                      ].initialEquity -
                      projectedPerformanceByCase[projectionsCase][
                        appreciationYear
                      ].appreciation
                  ).toLocaleString()
                : Math.round(
                    projectedPerformanceByCase[projectionsCase][
                      appreciationYear
                    ].distribution
                  ).toLocaleString()}
            </Typography>
          </Box>
          <Box>
            <Typography variant="c1" color="green3.main">
              {leadUuid === 'ac43df1d-7240-457e-8caa-8c176236bc35'
                ? '4% annualized cash flow'
                : '3% annualized distribution'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width="100%">
        <Box display="flex" gap="8px" flexDirection="column">
          <Box display="flex" gap="8px" flexDirection="row" alignItems="center">
            <Typography variant="p2" color="gray8.main">
              Appreciation
            </Typography>
            <Tooltip
              title={appreciationTooltip}
              arrow
              placement={isMobile ? 'bottom' : 'right'}
              enterTouchDelay={0}
            >
              <Box
                sx={{
                  display: 'inline',
                }}
              >
                <InfoIcon fill="#CBCDCF" width="12px" height="12px" />
              </Box>
            </Tooltip>
          </Box>
          <Box>
            <Typography
              variant="h4"
              color="gray8.main"
              sx={{
                fontWeight: 700,
              }}
            >
              $
              {Math.round(
                projectedPerformanceByCase[projectionsCase][appreciationYear]
                  .appreciation
              ).toLocaleString()}
            </Typography>
          </Box>
          <Box>
            <Typography variant="c1" color="green3.main">
              {annualAppreciationPercent}% annual appreciation
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ProjectionChartCard
