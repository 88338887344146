import React, { useState, useEffect } from 'react'
import { Box, Typography, styled } from '@mui/material'
import {
  FlockMountainIcon,
  flockTheme,
  PortfolioMapbox,
} from '@flock/shared-ui'

import { graphql, useStaticQuery } from 'gatsby'
import { GOOGLE_MAPS_API_KEY, MAPBOX_TOKEN } from '../../constants'
import generateGoogleMapUrl from '../../utils/googleMapUrl'

const MapImage = styled('img')({
  maxHeight: '240px',
  maxWidth: '100%',
  width: 'auto',
  borderRadius: '24px',
  filter: `drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.15))`,
  [flockTheme.breakpoints.down('sm')]: {
    minHeight: '10rem',
  },
})

const MapContainer = styled('div')({
  width: 'fit-content',
  alignSelf: 'center',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  textAlign: 'center',
  position: 'relative',
})

const MarkerRadius = styled('div')({
  position: 'absolute',
  color: 'blue',
  borderRadius: '50%',
  background: 'rgb(123, 148, 136, 0.2)',
  height: '177px',
  width: '177px',
  opacity: '90%',
  marginLeft: '29px',
  marginTop: '-205px',
})

const Marker = styled('div')({
  position: 'absolute',
  background: '#03341D',
  borderRadius: '50%',
  border: '8px solid #03341D',
  width: '8px',
  height: '8px',
  marginLeft: '105px',
  marginTop: '-136px',

  '&:after': {
    position: 'absolute',
    width: '0px',
    height: '0px',
    bottom: '-30px',
    left: '-6px',
    border: '10px solid transparent',
    borderTop: '17px solid #03341D',
    content: '""',
  },
})

const LargeImageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '240px',
  height: '240px',
  alignItems: 'center',
})

type OfferBasisComponentProps = {
  offerBaseType: string
  address?: string
  cityStateZip?: string
  latitude?: number
  longitude?: number
  propertyCondition?: string
  isOverriddenAddress?: boolean
}

const OfferBasisComponent = (props: OfferBasisComponentProps) => {
  const {
    offerBaseType,
    address,
    cityStateZip,
    latitude,
    longitude,
    propertyCondition,
    isOverriddenAddress,
  } = props

  const [homeConditionUrl, setHomeConditionUrl] = useState(
    'https://flock-static-images.s3.us-east-2.amazonaws.com/default-offer-page-home.svg'
  )
  useEffect(() => {
    // ex. of unknown address -  `11900 W Daviti Ln, Tucson, AZ 85736`
    if (offerBaseType === 'home_condition') {
      if (!isOverriddenAddress && address && cityStateZip) {
        const streetviewMetadataUrl = generateGoogleMapUrl({
          type: 'streetview/metadata',
          location: `${address} ${cityStateZip}`,
          key: GOOGLE_MAPS_API_KEY,
        })

        fetch(streetviewMetadataUrl)
          .then((response) => response.json()) // parse JSON from request
          .then((resultData) => {
            if (resultData.status === 'OK') {
              setHomeConditionUrl(
                generateGoogleMapUrl({
                  type: 'streetview',
                  location: `${address} ${cityStateZip}`,
                  size: '800x650',
                  key: GOOGLE_MAPS_API_KEY,
                  fov: 50,
                })
              )
            }
          })
      }
    }
  }, [address, cityStateZip, offerBaseType, isOverriddenAddress])

  const rentalCompsUrl = generateGoogleMapUrl({
    type: 'staticmap',
    location: `${address} ${cityStateZip}`,
    center: `${latitude},${longitude}`,
    size: '800x650',
    zoom: 16,
    scale: 2,
    key: GOOGLE_MAPS_API_KEY,
    style: `feature:poi|visibility:off`,
  })

  const { getPropertySummaryDetails } = useStaticQuery(graphql`
    query {
      getPropertySummaryDetails {
        propertyDetails {
          propertyUuid
          address {
            formattedStreet
            unit
            city
            state
            zipcode
            latitude
            longitude
          }
          propertyType
          beds
          baths
          halfBaths
          sqft
          yearBuilt
          acquisitionDate
        }
      }
    }
  `)

  const processedAssets =
    getPropertySummaryDetails?.propertyDetails?.map((asset: any) => ({
      ...asset,
      ...asset.address,
    })) || []

  let homeCondition = ''
  switch (propertyCondition) {
    case '5 - pristine':
      homeCondition = 'Pristine'
      break
    case '4 - great':
      homeCondition = 'Great'
      break
    case '3 - average':
      homeCondition = 'Average'
      break
    case '2 - below average':
      homeCondition = 'Below Average'
      break
    case '1 - requires complete renovation':
      homeCondition = 'Needs Renovation'
      break
    default:
      homeCondition = 'UNKNOWN'
      break
  }

  let largeImage = null

  switch (offerBaseType) {
    case 'home_condition':
      largeImage = (
        <Box position="relative" width="240px" height="240px">
          <Box
            component="img"
            height={240}
            width={240}
            sx={{ borderRadius: '24px' }}
            src={homeConditionUrl}
            alt={offerBaseType}
            zIndex={-1}
          />
          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="176px"
            display="flex"
            py="32px"
            flexDirection="column"
            alignItems="center"
            gap="16px"
            sx={{
              textAlign: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '24px',
              backdropFilter: 'blur(2px)',
            }}
          >
            <Box>
              <Typography
                variant="p3"
                lineHeight="1.125rem"
                fontWeight="300"
                color="common.white"
                sx={{ display: 'block' }}
              >
                You rated the condition of
              </Typography>
              <Typography
                variant="p3"
                lineHeight="1.125rem"
                fontWeight="100"
                color="common.white"
                sx={{ display: 'block' }}
              >
                {address}
              </Typography>
            </Box>

            <Typography
              variant="cta"
              fontSize="2rem"
              lineHeight="2.5rem"
              color="common.white"
              sx={{ display: 'block' }}
            >
              {homeCondition}
            </Typography>
            <Box>
              <FlockMountainIcon />
            </Box>
          </Box>
        </Box>
      )
      break
    case 'portfolio_requirements':
      largeImage = (
        <LargeImageContainer>
          <PortfolioMapbox
            mapboxToken={MAPBOX_TOKEN || ''}
            propertyAssets={processedAssets}
          />
        </LargeImageContainer>
      )
      break
    case 'rental_comps':
      largeImage = (
        <>
          <MapContainer>
            <Box
              component="img"
              height="240px"
              width="240px"
              sx={{ borderRadius: '24px' }}
              src={rentalCompsUrl}
              alt={offerBaseType}
            />
            <MarkerRadius />
            <Marker />
          </MapContainer>
        </>
      )
      break
    default:
      largeImage = (
        <MapContainer>
          <MapImage src={homeConditionUrl} alt={offerBaseType} />
        </MapContainer>
      )
      break
  }

  return <>{largeImage}</>
}

OfferBasisComponent.defaultProps = {
  address: '',
  cityStateZip: '',
  latitude: 0,
  longitude: 0,
  propertyCondition: '',
  isOverriddenAddress: false,
}

export default OfferBasisComponent
