import React from 'react'
import OfferQuestionsDesktop from './OfferQuestionsDesktop'
import OfferQuestionsMobile from './OfferQuestionsMobile'
import useOfferQuestions from './useOfferQuestions'

const OfferQuestions = () => {
  const presentationalProps = useOfferQuestions()
  const { isMobile } = presentationalProps

  if (isMobile) {
    return <OfferQuestionsMobile {...presentationalProps} />
  }
  return <OfferQuestionsDesktop {...presentationalProps} />
}

export default OfferQuestions
