import { Core_TransactionType } from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  BathroomIcon,
  BedroomIcon,
  HouseFilledIcon,
  HouseOutlinedIcon,
  LeftArrowFilledIcon,
  MoneyFilledIcon,
  PortfolioMapbox,
} from '@flock/shared-ui'
import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { MAPBOX_TOKEN } from '../../../constants'
import HomeDetail from '../HomeDetail'
import PortfolioHomeDetails from '../PortfolioHomeDetails'
import { OfferPortfolioPresentationalProps } from './offerPortfolioTypes'

const OfferPortfolioMobile = (props: OfferPortfolioPresentationalProps) => {
  const {
    streetViewUrl,
    street,
    bedrooms,
    bathrooms,
    homeType,
    transactionType,
    currentHomeCount,
    marketValueString,
    processedAssets,
    mobileConfig,
    leadName,
    portfolioName,
    portfolioDoorCount,
    portfolioAssetCount,
    portfolioCityState,
    portfolioSFRDoors,
    portfolioMultifamilyDoors,
    capex,
    offerPrice,
  } = props

  const HomeDetailComponents = (
    <Box display="flex" flexDirection="column" gap="6px">
      <Box display="flex" flexDirection="column" gap="8px">
        {bedrooms ? (
          <HomeDetail icon={BedroomIcon} text={`${bedrooms} bed`} />
        ) : null}
        {bathrooms ? (
          <HomeDetail icon={BathroomIcon} text={`${bathrooms} bath`} />
        ) : null}
      </Box>
      {homeType ? (
        <HomeDetail icon={HouseOutlinedIcon} text={homeType} />
      ) : null}
    </Box>
  )

  const TwoColumnHomeDetailComponents = (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      justifyContent="space-between"
    >
      <Box width="100%">
        <Box display="flex" flexDirection="column" gap="8px">
          <Box display="flex" flexDirection="row" gap="8px">
            {bedrooms ? (
              <HomeDetail icon={BedroomIcon} text={`${bedrooms} bed`} />
            ) : null}
            {bathrooms ? (
              <HomeDetail icon={BathroomIcon} text={`${bathrooms} bath`} />
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box width="100%">
          {homeType ? (
            <HomeDetail icon={HouseOutlinedIcon} text={homeType} />
          ) : null}
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      {transactionType === Core_TransactionType.TransactionTypePortfolio_721 ? (
        <PortfolioHomeDetails
          leadName={leadName}
          portfolioName={portfolioName}
          portfolioDoorCount={portfolioDoorCount}
          portfolioAssetCount={portfolioAssetCount}
          portfolioCityState={portfolioCityState}
          portfolioSFRDoors={portfolioSFRDoors}
          portfolioMultifamilyDoors={portfolioMultifamilyDoors}
          capex={capex}
          offerPrice={offerPrice}
        />
      ) : (
        <Box display="flex" flexDirection="row">
          {streetViewUrl ? (
            <>
              <Box
                component="img"
                src={streetViewUrl}
                alt={`Picture of ${street}`}
                width="50%"
                height="136px"
                sx={{
                  boxShadow: 8,
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                }}
              />

              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                padding="16px"
                sx={{
                  boxShadow: 8,
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  backgroundColor: 'green0.main',
                }}
              >
                <>{HomeDetailComponents}</>
              </Box>
            </>
          ) : (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              padding="16px"
              sx={{
                boxShadow: 8,
                borderRadius: '8px',
                backgroundColor: 'green0.main',
              }}
            >
              <Typography
                sx={{ paddingBottom: '16px', textAlign: 'center' }}
                variant="cta2"
              >
                {street}
              </Typography>

              <>{TwoColumnHomeDetailComponents}</>
            </Box>
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        width="100%"
        pt="16px"
      >
        <Paper
          sx={{
            padding: '0px',
            backgroundColor: 'rgb(235, 241, 241, 0.5)',
            borderRadius: '32px',
          }}
        >
          <Box display="flex" flexDirection="row" gap="8px" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              height="100%"
              sx={{ transform: 'rotate(-90deg)' }}
            >
              <LeftArrowFilledIcon height="48px" width="48px" />
            </Box>
            <Typography variant="p4" color="moneyGreen.main">
              {transactionType ===
              Core_TransactionType.TransactionTypePortfolio_721
                ? 'Your houses enter a professionally managed, diversified portfolio.'
                : 'Your house enters a professionally managed, diversified portfolio.'}
            </Typography>
          </Box>
        </Paper>
        <PortfolioMapbox
          propertyAssets={processedAssets}
          mapboxToken={MAPBOX_TOKEN || ''}
          mobileConfig={mobileConfig}
        />
        <Box
          display="flex"
          flexDirection="row"
          gap="16px"
          width="100%"
          justifyContent="space-between"
        >
          <Paper
            sx={{
              padding: '16px',
              backgroundColor: 'green0.main',
              borderRadius: '8px',
              width: '50%',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="8px"
              alignItems="left"
            >
              <HouseFilledIcon width="24px" height="24px" color="moneyGreen" />
              <Box>
                <Typography
                  variant="h4"
                  fontWeight={700}
                  color="moneyGreen.main"
                >
                  {currentHomeCount}
                </Typography>
                <Typography variant="p3" color="moneyGreen.main">
                  homes in Flock portfolio
                </Typography>
              </Box>
            </Box>
          </Paper>
          <Paper
            sx={{
              padding: '16px',
              backgroundColor: 'green0.main',
              borderRadius: '8px',
              width: '50%',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="8px"
              alignItems="left"
            >
              <MoneyFilledIcon width="24px" height="24px" color="moneyGreen" />
              <Box>
                <Typography
                  variant="h4"
                  fontWeight={700}
                  color="moneyGreen.main"
                >
                  ${marketValueString}
                </Typography>
                <Typography variant="p3" color="moneyGreen.main">
                  Flock portfolio value
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  )
}

export default OfferPortfolioMobile
