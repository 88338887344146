import { TrackedButton } from '@flock/shared-ui'
import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import ScheduleCall from '../../SharedComponents/ScheduleCall'
import { OfferTasksPresentationalProps } from './offerTasksTypes'

const OfferTasksMobile = (props: OfferTasksPresentationalProps) => {
  const {
    completedItems,
    todoItems,
    otherCalendlyProps,
    context,
    onClick,
    mobileActionText,
  } = props

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      py="16px"
      width="100%"
    >
      <Typography variant="cta" color="moneyGreen.main">
        See your next steps.
      </Typography>
      <Paper
        sx={{
          padding: '20px',
          backgroundColor: 'white',
          borderRadius: '25px',
        }}
      >
        <Box display="flex" flexDirection="column" gap="24px">
          <Box display="flex" flexDirection="column" gap="8px">
            <Box textAlign="center">
              <Typography variant="h4" color="gray5.main" fontWeight={500}>
                Tasks you&#39;ve completed.
              </Typography>
            </Box>
            {completedItems.map((item) => (
              <Typography key={item} variant="p2" color="gray5.main">
                <s>{item}</s>
              </Typography>
            ))}
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box textAlign="center">
              <Typography variant="h4" color="gray8.main" fontWeight={500}>
                Current action items.
              </Typography>
            </Box>
            <ScheduleCall {...otherCalendlyProps} user={context.user} />
            <TrackedButton
              onClick={onClick}
              disabled={!onClick}
              size="smallForm"
              sx={{
                px: { xs: '20px' }, // xs necessary for css priority
                fontSize: { xs: '15px' },
                border: 'none',
              }}
            >
              {mobileActionText}
            </TrackedButton>
          </Box>

          {todoItems.length > 0 && (
            <Box display="flex" flexDirection="column" gap="8px">
              <Box textAlign="center">
                <Typography variant="h4" color="gray8.main" fontWeight={500}>
                  Next steps.
                </Typography>
              </Box>
              {todoItems.map((item) => (
                <Typography key={item} variant="p2" color="moneyGreen.main">
                  {item}
                </Typography>
              ))}
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  )
}

export default OfferTasksMobile
