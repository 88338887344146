import React from 'react'
import OfferPageSidebarDesktop from './OfferPageSidebarDesktop'
import OfferPageSidebarTablet from './OfferPageSidebarTablet'
import OfferPageSidebarMobile from './OfferPageSidebarMobile'
import useOfferPageSidebar from './useOfferPageSidebar'
import { OfferPageSidebarProps } from './offerPageSidebarTypes'

const OfferPageSidebar = (props: OfferPageSidebarProps) => {
  const presentationalProps = useOfferPageSidebar(props)
  const { isMobile, isTablet } = presentationalProps

  if (isMobile) {
    return <OfferPageSidebarMobile {...presentationalProps} />
  }
  if (isTablet) {
    return <OfferPageSidebarTablet {...presentationalProps} />
  }
  return <OfferPageSidebarDesktop {...presentationalProps} />
}

export default OfferPageSidebar
