import React, { useContext } from 'react'
import {
  Box,
  Drawer,
  SvgIconProps,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material'
import { TrackedButton, CloseIcon, CloseMobileIcon } from '@flock/shared-ui'
import { useCalendly } from '../utils'
import ScheduleCall from '../SharedComponents/ScheduleCall'
import OfferPageContext from './OfferPageContext'

type OfferPageDrawerProps = {
  title: string
  icon?: React.FC<SvgIconProps>
  description: React.ReactNode
  graphic?: React.ReactNode
  isOpen: boolean
  onClose: () => void
}

const OfferPageDrawer = (props: OfferPageDrawerProps) => {
  const { title, icon: Icon, description, graphic, isOpen, onClose } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const context = useContext(OfferPageContext)
  const { onOpenCalendly, ...otherCalendlyProps } = useCalendly({
    utmMedium: 'questions-about-your-offer',
    scheduleUrl: context.calendlyLink || '',
  })
  return (
    // disableRestoreFocus because of https://github.com/mui/material-ui/issues/10756#issuecomment-375062360
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      PaperProps={{ sx: { width: isMobile ? '100%' : '416px' } }}
      disableRestoreFocus
    >
      <Box
        position="fixed"
        top={isMobile ? '28px' : '40px'}
        right={isMobile ? '28px' : '40px'}
      >
        <IconButton onClick={onClose}>
          {isMobile ? <CloseMobileIcon /> : <CloseIcon />}
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={isMobile ? '100%' : '416px'}
        height="100%"
        pt={isMobile ? '40px' : '120px'}
        pb="96px"
        sx={{ backgroundColor: 'gray1.main' }}
      >
        <Box
          maxWidth="240px"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gap="24px">
            <Box display="flex" gap="16px" flexWrap="nowrap">
              {Icon ? (
                <Box>
                  <Icon width="48px" height="48px" />{' '}
                </Box>
              ) : null}
              <Box>
                <Typography variant="p1" color="moneyGreen.main">
                  {title}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="p2" color="gray7.main">
                {description}
              </Typography>
            </Box>
            {graphic && <Box>{graphic}</Box>}
          </Box>
          <Box display="flex" flexDirection="column" gap="16px" mt="32px">
            <Typography variant="p2" color="moneyGreen.main">
              Questions about your valuation? We&apos;re happy to answer.
            </Typography>
            <ScheduleCall {...otherCalendlyProps} />
            <Box>
              <TrackedButton
                variant="secondary"
                size="smallForm"
                onClick={onOpenCalendly}
              >
                Schedule a call
              </TrackedButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

OfferPageDrawer.defaultProps = {
  icon: undefined,
  graphic: undefined,
}

export default OfferPageDrawer
