import React from 'react'
import FlexibleCashflowCalculatorDesktop from './FlexibleCashflowCalculatorDesktop'
import FlexibleCashflowCalculatorMobile from './FlexibleCashflowCalculatorMobile'
import useFlexibleCashflowCalculator from './useFlexibleCashflowCalculator'
import { FlexibleCashFlowProps } from './flexibleCashflowCalculatorTypes'

const AppreciationPotential = (props: FlexibleCashFlowProps) => {
  const presentationalProps = useFlexibleCashflowCalculator(props)
  const { isMobile } = presentationalProps
  if (isMobile) {
    return <FlexibleCashflowCalculatorMobile {...presentationalProps} />
  }
  return <FlexibleCashflowCalculatorDesktop {...presentationalProps} />
}

export default AppreciationPotential
